/* Code for desktop */
@media (min-width: 1024px) {
  body {
    margin: 0;
  }
  html {
    scroll-behavior: smooth;
  }
  :root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }

  .main-container {
    overflow: hidden;
  }

  .main-container,
  .main-container * {
    box-sizing: border-box;
  }

  input,
  select,
  textarea,
  button {
    outline: 0;
  }

  .main-container {
    position: relative;
    width: 100%;
    height: 7437.881px;
    margin: 0 auto;
    overflow: hidden;
  }
  .flex-row-ee {
    position: relative;
    width: 1140.952px;
    height: 72.192px;
    margin: 31.428px 0 0 149.523px;
    z-index: 53;
  }
  .div {
    position: absolute;
    width: 0.95px;
    height: 62.857px;
    top: 0;
    left: 164.762px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 46;
  }
  .button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: nowrap;
    gap: 3.81px;
    position: absolute;
    width: 171.429px;
    height: 67.429px;
    top: 4.764px;
    right: 0;
    padding: 25.714px 30.476px 25.714px 30.476px;
    background: #4ba629;
    z-index: 53;
    border-radius: 76.19px;
  }
  .contact-us {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 110.476px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.52px;
    z-index: 54;
    text-decoration: none;
  }
  .eaeccfefb-logo-crypto-template {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    width: 134.286px;
    height: 30.232px;
    top: 16.313px;
    left: 0;
    z-index: 42;
  }
  .header-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 3.81px;
    position: relative;
    width: 146.667px;
    z-index: 43;
    overflow: hidden;
  }
  .logo {
    flex-shrink: 0;
    position: relative;
    width: 134.095px;
    height: 30.232px;
    z-index: 44;
    overflow: hidden;
  }
  .group {
    position: absolute;
    width: 159.158px;
    height: 34.718px;
    top: 0;
    left: 0;
    background: url(./assets/images/a4aecf07-7be4-4b64-8f24-14feedd82afa.png)
      no-repeat center;
    background-size: cover;
    z-index: 45;
  }
  .navigation {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 38.095px;
    position: absolute;
    width: 508.286px;
    height: 29.333px;
    top: 17.143px;
    left: 196.191px;
    padding: 6.667px 0.95px 6.667px 0.95px;
    z-index: 47;
  }
  .home {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #4ba629;
    font-family: DM Sans, var(--default-font-family);
    font-size: 13.333333015441895px;
    font-weight: 900;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.33px;
    z-index: 48;
    text-shadow: 0 0 7.619px rgba(75, 166, 41, 0.8);
    text-decoration: none;
  }
  .about-us {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 13.333333015441895px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.33px;
    z-index: 49;
    text-decoration: none;
  }

  .about-us:active,
  .about-us:hover {
    color: #4ba629;
    text-shadow: 0 0 7.619px rgba(75, 166, 41, 0.8);
    outline: none;
  }

  .our-pillars {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 13.333333015441895px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.33px;
    z-index: 50;
    text-decoration: none;
  }
  .our-pillars:active,
  .our-pillars:hover {
    color: #4ba629;
    text-shadow: 0 0 7.619px rgba(75, 166, 41, 0.8);
    outline: none;
  }
  .mission {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 13.333333015441895px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.33px;
    z-index: 51;
    text-decoration: none;
  }
  .mission:active,
  .mission:hover {
    color: #4ba629;
    text-shadow: 0 0 7.619px rgba(75, 166, 41, 0.8);
    outline: none;
  }
  .products {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 13.333333015441895px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.33px;
    z-index: 52;
    text-decoration: none;
  }
  .products:active,
  .products:hover {
    color: #4ba629;
    text-shadow: 0 0 7.619px rgba(75, 166, 41, 0.8);
    outline: none;
  }
  .flex-row {
    position: relative;
    width: 1602.856px;
    height: 557.143px;
    margin: 103.999px 0 0 149.523px;
    z-index: 40;
  }
  .apple-computers {
    position: absolute;
    width: 924.762px;
    height: 100%;
    top: 0;
    left: 50%;
    background: url(./assets/images/newImage.png) no-repeat center;
    background-size: cover;
    transform: translate(-13.34%, 0);
    z-index: 40;
  }
  .frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 15.238px;
    position: absolute;
    height: 354.333px;
    top: 50%;
    right: 899.999px;
    left: 0;
    transform: translate(0, -53.36%);
    z-index: 31;
  }
  .innovating-for-better-lives {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    min-width: 0;
    font-family: DM Sans, var(--default-font-family);
    font-size: 62.85714340209961px;
    font-weight: 700;
    line-height: 76.183px;
    text-align: left;
    letter-spacing: -0.95px;
    z-index: 32;
  }
  .innovating {
    position: relative;
    color: #4ba629;
    font-family: DM Sans, var(--default-font-family);
    font-size: 62.85714340209961px;
    font-weight: 700;
    line-height: 76.183px;
    text-align: left;
    letter-spacing: -0.95px;
  }
  .for-better-lives {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 62.85714340209961px;
    font-weight: 700;
    line-height: 76.183px;
    text-align: left;
    letter-spacing: -0.95px;
  }
  .frame-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 26.667px;
    position: relative;
    width: 540.952px;
    z-index: 33;
  }
  .empowering-productivity {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 540.952px;
    min-width: 0;
    height: 92px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 22.85714340209961px;
    font-weight: 400;
    line-height: 45.714px;
    text-align: left;
    z-index: 34;
  }
  .div-2 {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 26.667px;
    position: relative;
    width: 197.143px;
    padding: 0 363.348px 0 0;
    z-index: 35;
  }
  .button-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 3.81px;
    position: relative;
    width: 196.429px;
    padding: 25.714px 45.714px 25.714px 45.714px;
    background: #4ba629;
    z-index: 36;
    border-radius: 76.19px;
  }
  .lets-start {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 105px;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.52px;
    z-index: 37;
    text-decoration: none;
  }
  .frame-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 5.714px;
    position: relative;
    width: 64.762px;
    margin: -7.143px 0 0 687.617px;
    opacity: 0.1;
    z-index: 237;
  }
  .scroll {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 64.762px;
    height: 16.19px;
    color: #ffffff;
    font-family: Neutral Face, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 15.817px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0.3px;
    z-index: 238;
  }
  .arrow-down {
    flex-shrink: 0;
    position: relative;
    width: 22.857px;
    height: 22.857px;
    background: url(./assets/images/273545da-376e-40a4-b949-9eb10c68ef66.png)
      no-repeat center;
    background-size: cover;
    z-index: 239;
    overflow: hidden;
  }
  .mask-group {
    position: relative;
    width: 100%;
    height: 600.952px;
    margin: 13.81px 0 0 0;
    background: url(./assets/images/6fe5f8d6-6626-4386-bee1-edd0fc02b0f4.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 56;
    overflow: visible auto;
  }

  .mask-group-24 {
    position: relative;
    width: 100%;
    height: 600.952px;
    margin: 80px 0 0 0;
    background: url(./assets/images/bggg.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 56;
    overflow: visible auto;
  }
  .frame-5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10.476px;
    position: relative;
    width: 560.952px;
    height: 300px;
    margin: 206.666px 0 0 774.285px;
    z-index: 57;
  }
  .shaping-a-smarter-world {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 560.952px;
    min-width: 0;
    height: 107px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 700;
    line-height: 53.333px;
    text-align: left;
    z-index: 58;
  }
  .at-inventcare {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 560.952px;
    min-width: 0;
    height: 183px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 400;
    line-height: 30.476px;
    text-align: justified;
    z-index: 59;
  }
  .flex-row-d {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 1238.094px;
    height: 61px;
    margin: 100px auto 0;
    z-index: 39;
    padding-top: 50px;
  }
  .our-pillars-of-innovation {
    flex-shrink: 0;
    position: relative;
    height: 53px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 700;
    line-height: 53px;
    text-align: left;
    white-space: nowrap;
    z-index: 38;
  }
  .driving-meaningful-progress {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 375.238px;
    height: 61px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 400;
    line-height: 30.48px;
    text-align: left;
    z-index: 39;
  }
  .flex-row-f {
    position: relative;
    width: 1291.427px;
    height: 485.714px;
    margin: 72px auto 0;
    z-index: 235;
  }
  .frame-6 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    width: 23.89%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 210;
  }
  .eaebefc-image-blog-crypto-template-p {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    min-width: 0;
    height: 173.333px;
    background: url(./assets/images/ece5aa14953966809b65c3db4725c847369cc169.png)
      no-repeat center;
    background-size: cover;
    z-index: 211;
  }
  .ios {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: wrap;
    flex-shrink: 0;
    gap: 9.524px;
    position: relative;
    min-width: 0;
    padding: 49.524px 20.952px 49.524px 20.952px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 212;
    overflow: hidden;
    border-radius: 0 0 32.381px 32.381px;
  }
  .simplifying-tasks {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 266.667px;
    height: 214px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14.285714149475098px;
    font-weight: 300;
    line-height: 26.667px;
    text-align: center;
    letter-spacing: 1.14px;
    z-index: 213;
  }
  .frame-7 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    width: 23.89%;
    height: 100%;
    top: 0;
    left: 25.37%;
    z-index: 217;
  }
  .eaebefc-image-blog-crypto-template-p-8 {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    min-width: 0;
    height: 173.333px;
    background: url(./assets/images/2.png)
      no-repeat center;
    background-size: cover;
    z-index: 218;
  }
  .ios-9 {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: wrap;
    flex-shrink: 0;
    gap: 9.524px;
    position: relative;
    min-width: 0;
    padding: 49.524px 20.952px 49.524px 20.952px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 219;
    overflow: hidden;
    border-radius: 0 0 32.381px 32.381px;
  }
  .creating-platforms {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 266.667px;
    height: 214px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14.285714149475098px;
    font-weight: 300;
    line-height: 26.667px;
    text-align: center;
    letter-spacing: 1.14px;
    z-index: 220;
  }
  .frame-a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    width: 23.89%;
    height: 100%;
    top: 0;
    left: 50.74%;
    z-index: 224;
  }
  .eaebefc-image-blog-crypto-template-p-b {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    min-width: 0;
    height: 173.333px;
    background: url(./assets/images/3.png)
      no-repeat center;
    background-size: cover;
    z-index: 225;
  }
  .ios-c {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: wrap;
    flex-shrink: 0;
    gap: 9.524px;
    position: relative;
    min-width: 0;
    padding: 49.524px 20.952px 49.524px 20.952px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 226;
    overflow: hidden;
    border-radius: 0 0 32.381px 32.381px;
  }
  .encouraging-self-expression {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 266.667px;
    height: 214px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14.285714149475098px;
    font-weight: 300;
    line-height: 26.667px;
    text-align: center;
    letter-spacing: 1.14px;
    z-index: 227;
  }
  .frame-d {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    width: 23.89%;
    height: 100%;
    top: 0;
    left: 76.11%;
    z-index: 231;
  }
  .eaebefc-image-blog-crypto-template-p-e {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    min-width: 0;
    height: 173.333px;
    background: url(./assets/images/4.png)
      no-repeat center;
    background-size: cover;
    z-index: 232;
  }
  .ios-f {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 9.524px;
    position: relative;
    min-width: 0;
    padding: 49.524px 20.952px 49.524px 20.952px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 233;
    overflow: hidden;
    border-radius: 0 0 32.381px 32.381px;
  }
  .prioritizing-well-being {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    width: 266.667px;
    height: 214px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14.285714149475098px;
    font-weight: 300;
    line-height: 26.667px;
    text-align: center;
    letter-spacing: 1.14px;
    z-index: 234;
  }
  .div-10 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    position: absolute;
    width: 14.38%;
    height: 9%;
    top: 31.18%;
    left: 4.75%;
    padding: 10.895px 30.476px 10.895px 30.476px;
    background: #4ba629;
    z-index: 214;
    border-radius: 1210.549px;
  }
  .productivity {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 22px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16.140657424926758px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.85px;
    z-index: 215;
  }
  .div-11 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    position: absolute;
    width: 11.36%;
    height: 9%;
    top: 31.18%;
    left: 31.63%;
    padding: 10.895px 30.476px 10.895px 30.476px;
    background: #4ba629;
    z-index: 221;
    border-radius: 1210.549px;
  }
  .learning {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 22px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16.14065933227539px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.85px;
    z-index: 222;
  }
  .div-12 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    position: absolute;
    width: 12.32%;
    height: 9%;
    top: 31.18%;
    left: 56.56%;
    padding: 10.895px 30.476px 10.895px 30.476px;
    background: #4ba629;
    z-index: 228;
    border-radius: 1210.549px;
  }
  .creativity {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 22px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16.14065933227539px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.85px;
    z-index: 229;
  }
  .div-13 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    position: absolute;
    width: 9.59%;
    height: 9%;
    top: 31.18%;
    left: 83.27%;
    padding: 10.895px 30.476px 10.895px 30.476px;
    background: #4ba629;
    z-index: 235;
    border-radius: 1210.549px;
  }
  .safety {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 22px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16.14065933227539px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.85px;
    z-index: 236;
  }
  .mask-group-14 {
    position: relative;
    width: 100%;
    height: 600.952px;
    margin: 72.381px 0 0 0;
    z-index: 61;
    -webkit-mask-image: url(./assets/images/2fe9f57b-0099-4056-85ec-67dba6ac15f9.png);
    -webkit-mask-size: cover;
    opacity: 0;
  }
  .group-15 {
    position: absolute;
    width: 44.07%;
    height: 113.43%;
    top: 6.56%;
    left: 51.46%;
    background: url(./assets/images/ba993ad7-7547-44cc-82b2-99743252e5dc.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 63;
  }
  .rectangle {
    position: absolute;
    width: 100%;
    height: 81.3%;
    top: 18.7%;
    left: 0;
    background: #4ba629;
    z-index: 62;
  }
  .frame-16 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 19.048px;
    position: absolute;
    height: 51.35%;
    top: 33.44%;
    right: 674.19px;
    left: 100.953px;
    z-index: 64;
  }
  .at-inventcare-17 {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    min-width: 0;
    font-family: DM Sans, var(--default-font-family);
    font-size: 34.28571319580078px;
    font-weight: 700;
    line-height: 53.333px;
    text-align: left;
    z-index: 65;
  }
  .at {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 34.28571319580078px;
    font-weight: 500;
    line-height: 53.333px;
    text-align: left;
  }
  .nbsp {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 34.28571319580078px;
    font-weight: 700;
    line-height: 53.333px;
    text-align: left;
  }
  .inventcare {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 34.28571319580078px;
    font-weight: 900;
    line-height: 53.333px;
    text-align: left;
  }
  .nbsp-18 {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 34.28571319580078px;
    font-weight: 700;
    line-height: 53.333px;
    text-align: left;
  }
  .at-19 {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 34.28571319580078px;
    font-weight: 500;
    line-height: 53.333px;
    text-align: left;
  }
  .frame-1a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 7.619px;
    position: relative;
    min-width: 0;
    z-index: 66;
  }
  .div-1b {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 11.333px;
    position: relative;
    width: 546px;
    z-index: 67;
  }
  .div-1c {
    flex-shrink: 0;
    position: relative;
    width: 30.667px;
    height: 30.667px;
    background: #4ba629;
    z-index: 68;
    overflow: hidden;
    border-radius: 8.667px;
  }
  .icon-spec-crypto-template {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 18.667px;
    margin: 4.666px 0 0 6px;
    z-index: 69;
  }
  .icon-spec-crypto-template-1d {
    flex-shrink: 0;
    position: relative;
    width: 18.667px;
    height: 22px;
    background: url(./assets/images/176c5f55-883c-40bc-8243-e3fcc7165244.png)
      no-repeat center;
    background-size: cover;
    z-index: 70;
    overflow: hidden;
  }
  .boost-productivity {
    flex-shrink: 0;
    position: relative;
    width: 504px;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 900;
    line-height: 22px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 71;
  }
  .boost-productivity-1e {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.999999046325684px;
    font-weight: 900;
    line-height: 21.336px;
    text-align: left;
  }
  .nbsp-1f {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 900;
    line-height: 21.336px;
    text-align: left;
  }
  .empowering-you {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 600;
    line-height: 21.336px;
    text-align: left;
  }
  .div-20 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 11.333px;
    position: relative;
    width: 558px;
    z-index: 72;
  }
  .div-21 {
    flex-shrink: 0;
    position: relative;
    width: 30.667px;
    height: 30.667px;
    background: #4ba629;
    z-index: 73;
    overflow: hidden;
    border-radius: 8.667px;
  }
  .icon-spec-crypto-template-22 {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 18.667px;
    margin: 4.475px 0 0 6px;
    z-index: 74;
  }
  .icon-spec-crypto-template-23 {
    flex-shrink: 0;
    position: relative;
    width: 18.667px;
    height: 22px;
    background: url(./assets/images/4ec4831b-b1ff-4ee4-920a-3992b231fa38.png)
      no-repeat center;
    background-size: cover;
    z-index: 75;
    overflow: hidden;
  }
  .foster-creativity {
    flex-shrink: 0;
    position: relative;
    width: 516px;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 900;
    line-height: 22px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 76;
  }
  .foster-creativity-24 {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.999999046325684px;
    font-weight: 900;
    line-height: 21.336px;
    text-align: left;
  }
  .nbsp-25 {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 900;
    line-height: 21.336px;
    text-align: left;
  }
  .unlocking-your-potential {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 600;
    line-height: 21.336px;
    text-align: left;
  }
  .a {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 11.333px;
    position: relative;
    width: 506px;
    z-index: 77;
  }
  .div-26 {
    flex-shrink: 0;
    position: relative;
    width: 30.667px;
    height: 30.667px;
    background: #4ba629;
    z-index: 78;
    overflow: hidden;
    border-radius: 8.667px;
  }
  .icon-spec-crypto-template-27 {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 18.667px;
    margin: 4.285px 0 0 6px;
    z-index: 79;
  }
  .icon-spec-crypto-template-28 {
    flex-shrink: 0;
    position: relative;
    width: 18.667px;
    height: 22px;
    background: url(./assets/images/b36c88c9-731d-48bf-9b01-24cc466df544.png)
      no-repeat center;
    background-size: cover;
    z-index: 80;
    overflow: hidden;
  }
  .track-progress {
    flex-shrink: 0;
    position: relative;
    width: 464px;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 900;
    line-height: 22px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 81;
  }
  .track-progress-29 {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.999999046325684px;
    font-weight: 900;
    line-height: 21.336px;
    text-align: left;
  }
  .nbsp-2a {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 900;
    line-height: 21.336px;
    text-align: left;
  }
  .helping-you-measure {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 600;
    line-height: 21.336px;
    text-align: left;
  }
  .a-2b {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 11.333px;
    position: relative;
    width: 533px;
    z-index: 82;
  }
  .div-2c {
    flex-shrink: 0;
    position: relative;
    width: 30.667px;
    height: 30.667px;
    background: #4ba629;
    z-index: 83;
    overflow: hidden;
    border-radius: 8.667px;
  }
  .icon-spec-crypto-template-2d {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 18.667px;
    margin: 4.332px 0 0 6px;
    z-index: 84;
  }
  .icon-spec-crypto-template-2e {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 18.667px;
    z-index: 85;
  }
  .icon-spec-crypto-template-2f {
    flex-shrink: 0;
    position: relative;
    width: 18.667px;
    height: 22px;
    background: url(./assets/images/1260e15e-50a1-4050-b6de-82755fda9aa9.png)
      no-repeat center;
    background-size: cover;
    z-index: 86;
    overflow: hidden;
  }
  .ensure-safety {
    flex-shrink: 0;
    position: relative;
    width: 491px;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 900;
    line-height: 22px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 87;
  }
  .ensure-safety-30 {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.999999046325684px;
    font-weight: 900;
    line-height: 21.336px;
    text-align: left;
  }
  .nbsp-31 {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 900;
    line-height: 21.336px;
    text-align: left;
  }
  .keeping-you-secure {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 600;
    line-height: 21.336px;
    text-align: left;
  }
  .a-32 {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 11.333px;
    position: relative;
    z-index: 88;
  }
  .div-33 {
    flex-shrink: 0;
    position: relative;
    width: 30.667px;
    height: 30.667px;
    background: #4ba629;
    z-index: 89;
    overflow: hidden;
    border-radius: 8.667px;
  }
  .icon-spec-crypto-template-34 {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 18.667px;
    margin: 4.857px 0 0 6px;
    z-index: 90;
  }
  .icon-spec-crypto-template-35 {
    flex-shrink: 0;
    position: relative;
    width: 18.667px;
    height: 22px;
    background: url(./assets/images/f2c6cbef-0e45-4e3c-9bd3-cb5c1f9a050c.png)
      no-repeat center;
    background-size: cover;
    z-index: 91;
    overflow: hidden;
  }
  .quality-digital-life {
    flex-shrink: 0;
    position: relative;
    width: 622.857px;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 900;
    line-height: 22px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 92;
  }
  .quality-digital-life-36 {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.999999046325684px;
    font-weight: 900;
    line-height: 21.336px;
    text-align: left;
  }
  .empty {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.999999046325684px;
    font-weight: 900;
    line-height: 21.336px;
    text-align: left;
  }
  .balanced-technology-use {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 11.999999046325684px;
    font-weight: 600;
    line-height: 21.336px;
    text-align: left;
  }
  .flex-row-bbd {
    position: relative;
    width: 100%;
    height: 80.238px;
    margin: 99.048px 0 0 0;
    z-index: 94;
  }
  .rectangle-37 {
    position: absolute;
    width: 313.414px;
    height: 73.176px;
    top: 0;
    left: 0;
    background: url(./assets/images/b9147d7a-ca34-4707-bad5-60053e99a963.png)
      no-repeat center;
    background-size: cover;
    z-index: 29;
  }
  .products-38 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 165px;
    height: 53px;
    top: calc(50% - 27.06px);
    left: calc(50% - 102.42px);
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 30.4761905670166px;
    font-weight: 900;
    line-height: 52.4px;
    text-align: center;
    white-space: nowrap;
    z-index: 30;
  }
  .champsverse {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 251px;
    height: 53px;
    top: calc(50% - -1.12px);
    left: calc(40%);
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 700;
    line-height: 52.4px;
    text-align: center;
    white-space: nowrap;
    z-index: 94;
  }
  .transforming-learning {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 503.81px;
    height: 50px;
    margin: 21.285px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: center;
    z-index: 95;
  }
  .flex-row-dfb {
    position: relative;
    width: 1123.809px;
    height: 592.382px;
    margin: 80.477px auto 0;
    z-index: 116;
  }
  .div-39 {
    position: absolute;
    width: 309.524px;
    height: 280.952px;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(-181.54%, -105.42%);
    z-index: 96;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .div-3a {
    position: relative;
    width: 64.762px;
    height: 64.762px;
    margin: 45.715px 0 0 21.906px;
    background: #4ba629;
    z-index: 97;
    overflow: hidden;
    border-radius: 19.048px;
  }
  .student-center {
    position: relative;
    width: 45.714px;
    height: 45.714px;
    margin: 7.619px 0 0 9.523px;
    background: url(./assets/images/a51f5ff074d3b6f466bd34260bf31ef39f514aa2.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 98;
  }
  .learn-grow {
    display: block;
    position: relative;
    height: 25px;
    margin: 19.047px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 700;
    line-height: 24.754px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.86px;
    z-index: 99;
  }
  .safe-fun-space {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 265.714px;
    height: 75px;
    margin: 6.43px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 100;
  }
  .ios-3b {
    position: absolute;
    width: 454.286px;
    height: 592.381px;
    top: 50%;
    left: 50%;
    font-size: 0px;
    background: #4ba629;
    transform: translate(-49.9%, -50%);
    z-index: 116;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .ios-android-app {
    display: block;
    position: relative;
    height: 25px;
    margin: 41.904px 0 0 31.43px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 19.047618865966797px;
    font-weight: 700;
    line-height: 24.762px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.95px;
    z-index: 117;
  }
  .accessible-platforms {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 402.857px;
    height: 50px;
    margin: 20.715px 0 0 31.43px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 118;
  }
  .iphones {
    position: relative;
    width: 337.143px;
    height: 688.571px;
    margin: 34.762px 0 0 45.715px;
    z-index: 119;
  }
  .body {
    position: absolute;
    width: 98.46%;
    height: 100.05%;
    top: 0;
    left: 0.85%;
    background: url(./assets/images/0f836737-47b1-40d4-a2bf-03a317708cc4.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 120;
  }
  .screen-replace {
    position: absolute;
    width: 90.56%;
    height: 96.04%;
    top: 1.94%;
    left: 4.8%;
    background: url(./assets/images/bb8497e2a1b3fe14311c045c3332d7cb14c4e149.png)
      no-repeat center;
    background-size: cover;
    z-index: 126;
    border-radius: 39.21px;
  }
  .dinamy-island {
    position: absolute;
    width: 26.52%;
    height: 3.74%;
    top: 3.04%;
    left: 36.67%;
    background: #131316;
    z-index: 127;
    border-radius: 38.508px;
  }
  .buttons {
    position: absolute;
    width: 100.15%;
    height: 22.56%;
    top: 17.57%;
    left: 0;
    z-index: 121;
    border-radius: 1.118px;
  }
  .flex-column-ac {
    position: absolute;
    width: 0.85%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 124;
  }
  .silence-button {
    position: relative;
    width: 2.853px;
    height: 24.774px;
    margin: 0 0 0 0;
    background: #131316;
    z-index: 124;
    border-radius: 1.118px;
  }
  .plus-button {
    position: relative;
    width: 1.902px;
    height: 48.595px;
    margin: 20.962px 0 0 0;
    background: #131316;
    z-index: 122;
    border-radius: 1.118px;
  }
  .plus-button-3c {
    position: relative;
    width: 1.902px;
    height: 48.595px;
    margin: 12.387px 0 0 0;
    background: #131316;
    z-index: 123;
    border-radius: 1.118px;
  }
  .power-button {
    display: flex;
    align-items: flex-start;
    position: absolute;
    width: 0.85%;
    height: 49.08%;
    top: 50.31%;
    left: 99.15%;
    background: #131316;
    z-index: -Infinity;
    border-radius: 1.118px;
  }
  .div-3d {
    position: absolute;
    width: 309.524px;
    height: 280.952px;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(81.54%, -105.42%);
    z-index: 101;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .div-3e {
    position: relative;
    width: 64.762px;
    height: 64.762px;
    margin: 45.715px 0 0 21.906px;
    background: #4ba629;
    z-index: 102;
    overflow: hidden;
    border-radius: 19.048px;
  }
  .bright-mind {
    position: relative;
    width: 45.714px;
    height: 45.714px;
    margin: 9.523px 0 0 9.523px;
    background: url(./assets/images/4478c8465ba442557fef64971893da2bcfea5a95.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 103;
  }
  .creativity-3f {
    display: block;
    position: relative;
    height: 25px;
    margin: 19.047px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 700;
    line-height: 24.754px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.86px;
    z-index: 104;
  }
  .young-artists {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 265.714px;
    height: 75px;
    margin: 6.43px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 105;
  }
  .div-40 {
    position: absolute;
    width: 309.524px;
    height: 280.952px;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(-181.54%, 5.42%);
    z-index: 106;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .div-41 {
    position: relative;
    width: 64.762px;
    height: 64.762px;
    margin: 45.715px 0 0 21.906px;
    background: #4ba629;
    z-index: 107;
    overflow: hidden;
    border-radius: 19.048px;
  }
  .safety-care {
    position: relative;
    width: 45.714px;
    height: 45.714px;
    margin: 10.301px 0 0 8.57px;
    background: url(./assets/images/a3be43486c5a3ce16a972333f888f116b287c2aa.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 108;
  }
  .safety-well-being {
    display: block;
    position: relative;
    height: 25px;
    margin: 19.047px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 700;
    line-height: 24.754px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.86px;
    z-index: 109;
  }
  .safe-digital-space {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 265.714px;
    height: 75px;
    margin: 6.43px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 110;
  }
  .div-42 {
    position: absolute;
    width: 309.524px;
    height: 280.952px;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(81.54%, 5.42%);
    z-index: 111;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .div-43 {
    position: relative;
    width: 64.762px;
    height: 64.762px;
    margin: 45.715px 0 0 21.906px;
    background: #4ba629;
    z-index: 112;
    overflow: hidden;
    border-radius: 19.048px;
  }
  .guardian {
    position: relative;
    width: 45.714px;
    height: 45.714px;
    margin: 9.523px 0 0 9.523px;
    background: url(./assets/images/46af49cb2aa960bbd5b420b803958dd7d8f36d3f.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 113;
  }
  .parental-tools {
    display: block;
    position: relative;
    height: 25px;
    margin: 19.047px 0 0 23.809px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 700;
    line-height: 24.754px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.86px;
    z-index: 114;
  }
  .easy-to-use-features {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 265.714px;
    height: 75px;
    margin: 6.43px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 115;
  }
  .champsverse-iot {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 351px;
    height: 53px;
    margin: 150.475px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 700;
    line-height: 52.4px;
    text-align: center;
    white-space: nowrap;
    z-index: 129;
  }
  .revolutionizing-childrens-play {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 472.381px;
    height: 61px;
    margin: 14.619px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 400;
    line-height: 30.48px;
    text-align: center;
    z-index: 130;
  }
  .flex-row-f-44 {
    position: relative;
    width: 1123.809px;
    height: 592.382px;
    margin: 76.143px auto 0;
    z-index: 151;
  }
  .div-45 {
    position: absolute;
    width: 309.524px;
    height: 280.952px;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(-181.54%, -105.42%);
    z-index: 131;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .div-46 {
    position: relative;
    width: 64.762px;
    height: 64.762px;
    margin: 45.715px 0 0 21.906px;
    background: #4ba629;
    z-index: 132;
    overflow: hidden;
    border-radius: 19.048px;
  }
  .fast-forward {
    position: relative;
    width: 45.714px;
    height: 45.714px;
    margin: 9.523px 0 0 9.523px;
    background: url(./assets/images/1dda445150e7c58aa5eb264bafa79cfcc9297ce8.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 133;
  }
  .next-gen-toy-design {
    display: block;
    position: relative;
    height: 25px;
    margin: 19.047px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 700;
    line-height: 24.754px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.86px;
    z-index: 134;
  }
  .cutting-edge-toys {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 265.714px;
    height: 75px;
    margin: 6.43px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 135;
  }
  .ios-47 {
    position: absolute;
    width: 454.286px;
    height: 592.381px;
    top: 50%;
    left: 50%;
    font-size: 0px;
    transform: translate(-49.9%, -50%);
    z-index: 151;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .lorem-ipsum {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 364.762px;
    height: 50px;
    margin: 49.523px 0 0 31.43px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 152;
  }
  .iphones-48 {
    position: relative;
    width: 502.857px;
    height: 688.571px;
    margin: 34.762px 0 0 -24.762px;
    z-index: 153;
  }
  .screen-replace-here {
    position: absolute;
    width: 90.53%;
    height: 128.77%;
    top: -42.47%;
    left: 4.73%;
    background: url(./assets/images/80dc1ba7-e572-43e5-952a-7e14852c234c.png)
      no-repeat center;
    background-size: 100% 70%;
    z-index: 160;
  }
  .body-49 {
    position: absolute;
    width: 98.46%;
    height: 100.05%;
    top: 0;
    left: 0.85%;
    background: url(./assets/images/88aa26a9-2c41-4a26-820d-450b7a298c5d.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 154;
  }
  .buttons-4a {
    position: absolute;
    width: 100.15%;
    height: 22.56%;
    top: 17.57%;
    left: 0;
    z-index: 155;
    border-radius: 1.118px;
  }
  .flex-column-e {
    position: absolute;
    width: 0.85%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 158;
  }
  .silence-button-4b {
    position: relative;
    width: 4.256px;
    height: 24.774px;
    margin: 0 0 0 0;
    background: rgba(19, 19, 22, 0);
    z-index: 158;
    border-radius: 1.118px;
  }
  .wrapper-11 {
    position: relative;
    width: 2.837px;
    height: 48.595px;
    margin: 20.962px 0 0 0;
    background: rgba(19, 19, 22, 0);
    z-index: 156;
    border-radius: 1.118px;
  }
  .wrapper-12 {
    position: relative;
    width: 2.837px;
    height: 48.595px;
    margin: 12.387px 0 0 0;
    background: rgba(19, 19, 22, 0);
    z-index: 157;
    border-radius: 1.118px;
  }
  .power-button-4c {
    display: flex;
    align-items: flex-start;
    position: absolute;
    width: 0.85%;
    height: 49.08%;
    top: 50.31%;
    left: 99.15%;
    background: rgba(19, 19, 22, 0);
    z-index: -Infinity;
    border-radius: 1.118px;
  }
  .div-4d {
    position: absolute;
    width: 309.524px;
    height: 280.952px;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(81.54%, -105.42%);
    z-index: 136;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .div-4e {
    position: relative;
    width: 64.762px;
    height: 64.762px;
    margin: 45.715px 0 0 21.906px;
    background: #4ba629;
    z-index: 137;
    overflow: hidden;
    border-radius: 19.048px;
  }
  .internet {
    position: relative;
    width: 45.714px;
    height: 45.714px;
    margin: 9.523px 0 0 9.523px;
    background: url(./assets/images/23e9748bf5692e4b86582e0306cecd0bf6605b4a.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 138;
  }
  .reduced-online-time {
    display: block;
    position: relative;
    height: 25px;
    margin: 19.047px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 700;
    line-height: 24.754px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.86px;
    z-index: 139;
  }
  .offline-entertainment-options {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 265.714px;
    height: 75px;
    margin: 6.43px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 140;
  }
  .div-4f {
    position: absolute;
    width: 309.524px;
    height: 280.952px;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(-181.54%, 5.42%);
    z-index: 141;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .div-50 {
    position: relative;
    width: 64.762px;
    height: 64.762px;
    margin: 45.715px 0 0 21.906px;
    background: #4ba629;
    z-index: 142;
    overflow: hidden;
    border-radius: 19.048px;
  }
  .ping-pong {
    position: relative;
    width: 45.714px;
    height: 45.714px;
    margin: 9.523px 0 0 9.523px;
    background: url(./assets/images/d0ee65febd96cfcdd8ea484a7115e46817aab03e.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 143;
  }
  .engaging-fun-games {
    display: block;
    position: relative;
    height: 25px;
    margin: 19.047px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 700;
    line-height: 24.754px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.86px;
    z-index: 144;
  }
  .interactive-games {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 265.714px;
    height: 75px;
    margin: 6.43px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 145;
  }
  .div-51 {
    position: absolute;
    width: 309.524px;
    height: 280.952px;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(81.54%, 5.42%);
    z-index: 146;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .div-52 {
    position: relative;
    width: 64.762px;
    height: 64.762px;
    margin: 45.715px 0 0 21.906px;
    background: #4ba629;
    z-index: 147;
    overflow: hidden;
    border-radius: 19.048px;
  }
  .development-skill {
    position: relative;
    width: 45.714px;
    height: 45.714px;
    margin: 9.523px 0 0 9.523px;
    background: url(./assets/images/55ae2f6c1bb34ea7f2b458896944556d6b9d8e64.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 148;
  }
  .enhanced-development {
    display: block;
    position: relative;
    height: 25px;
    margin: 19.047px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 700;
    line-height: 24.754px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.86px;
    z-index: 149;
  }
  .boosting-abilities {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 265.714px;
    height: 75px;
    margin: 6.43px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 150;
  }
  .inventcare-safety-solutions {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 490px;
    height: 53px;
    margin: 150.475px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 700;
    line-height: 52.4px;
    text-align: center;
    white-space: nowrap;
    z-index: 162;
  }
  .peace-of-mind {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 362.857px;
    height: 61px;
    margin: 14.619px 0 0 538.094px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 400;
    line-height: 30.48px;
    text-align: center;
    z-index: 163;
  }
  .flex-row-cb {
    position: relative;
    width: 1123.809px;
    height: 592.382px;
    margin: 76.143px auto 0;
    z-index: 184;
  }
  .div-53 {
    position: absolute;
    width: 309.524px;
    height: 280.952px;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(-181.54%, -105.42%);
    z-index: 164;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .div-54 {
    position: relative;
    width: 64.762px;
    height: 64.762px;
    margin: 45.715px 0 0 21.906px;
    background: #4ba629;
    z-index: 165;
    overflow: hidden;
    border-radius: 19.048px;
  }
  .protect {
    position: relative;
    width: 45.714px;
    height: 45.714px;
    margin: 9.523px 0 0 9.523px;
    background: url(./assets/images/5a25c34f43aa7fd3b353d7bb98f8df7e4a44b691.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 166;
  }
  .ensuring-safety {
    display: block;
    position: relative;
    height: 25px;
    margin: 19.047px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 700;
    line-height: 24.754px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.86px;
    z-index: 167;
  }
  .well-being-security {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 217.143px;
    height: 75px;
    margin: 6.428px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 168;
  }
  .ios-55 {
    position: absolute;
    width: 454.286px;
    height: 592.381px;
    top: 50%;
    left: 50%;
    background: url(./assets/images/f2a94d90682f30428ae7805abaf8d4b56e1e2d58.png)
      no-repeat center;
    background-size: cover;
    transform: translate(-49.9%, -50%);
    z-index: 184;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .div-56 {
    position: absolute;
    width: 309.524px;
    height: 280.952px;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(81.54%, -105.42%);
    z-index: 169;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .div-57 {
    position: relative;
    width: 64.762px;
    height: 64.762px;
    margin: 45.715px 0 0 21.906px;
    background: #4ba629;
    z-index: 170;
    overflow: hidden;
    border-radius: 19.048px;
  }
  .private-wall-mount-camera {
    position: relative;
    width: 45.714px;
    height: 45.714px;
    margin: 9.523px 0 0 9.523px;
    background: url(./assets/images/b70b6909ba281183d503239b729be6db6f740108.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 171;
  }
  .surveillance {
    display: block;
    position: relative;
    height: 25px;
    margin: 19.047px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 700;
    line-height: 24.754px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.86px;
    z-index: 172;
  }
  .awareness-protection {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 265.714px;
    height: 75px;
    margin: 6.428px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 173;
  }
  .div-58 {
    position: absolute;
    width: 309.524px;
    height: 280.952px;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(-181.54%, 5.42%);
    z-index: 174;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .div-59 {
    position: relative;
    width: 64.762px;
    height: 64.762px;
    margin: 45.715px 0 0 21.906px;
    background: #4ba629;
    z-index: 175;
    overflow: hidden;
    border-radius: 19.048px;
  }
  .processor {
    position: relative;
    width: 41.905px;
    height: 41.905px;
    margin: 11.809px 0 0 11.43px;
    background: url(./assets/images/d206df11311ffcc16675c8939a8aa2cb8ddcdc06.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 176;
  }
  .advanced-technology {
    display: block;
    position: relative;
    height: 25px;
    margin: 19.047px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 700;
    line-height: 24.754px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.86px;
    z-index: 177;
  }
  .safety-convenience {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 265.714px;
    height: 75px;
    margin: 6.43px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 178;
  }
  .div-5a {
    position: absolute;
    width: 309.524px;
    height: 280.952px;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(81.54%, 5.42%);
    z-index: 179;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .div-5b {
    position: relative;
    width: 64.762px;
    height: 64.762px;
    margin: 45.715px 0 0 21.906px;
    background: #4ba629;
    z-index: 180;
    overflow: hidden;
    border-radius: 19.048px;
  }
  .male-user {
    position: relative;
    width: 45.714px;
    height: 45.714px;
    margin: 9.205px 0 0 9.523px;
    background: url(./assets/images/a77954abbdc5359c3b398aaed29b9aa4ca9d3b49.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 181;
  }
  .user-friendly-access {
    display: block;
    position: relative;
    height: 25px;
    margin: 19.047px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 700;
    line-height: 24.754px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.86px;
    z-index: 182;
  }
  .intuitive-solutions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 265.714px;
    height: 75px;
    margin: 6.43px 0 0 22.855px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: left;
    z-index: 183;
  }
  .flex-row-eef {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 1136.188px;
    height: 61px;
    margin: 138.094px auto 0;
    z-index: 186;
  }
  .download-champsverse {
    flex-shrink: 0;
    position: relative;
    height: 53px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 700;
    line-height: 53px;
    text-align: left;
    white-space: nowrap;
    z-index: 185;
  }
  .android-ios-platforms {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 439.048px;
    height: 61px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 400;
    line-height: 30.48px;
    text-align: left;
    z-index: 186;
  }
  .flex-row- {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 1140.952px;
    height: 738.095px;
    margin: 38.047px auto 0;
    z-index: 202;
  }
  .div-5c {
    flex-shrink: 0;
    position: relative;
    width: 536.19px;
    height: 738.095px;
    font-size: 0px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 187;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .download-for-ios {
    display: block;
    position: relative;
    height: 53px;
    margin: 45.715px 0 0 45.715px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 700;
    line-height: 52.4px;
    text-align: left;
    white-space: nowrap;
    z-index: 188;
  }
  .ios-5d {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 7.619px;
    position: relative;
    width: 184.476px;
    height: 66.667px;
    margin: 42.236px 0 0 45.715px;
    padding: 25.714px 30.476px 25.714px 30.476px;
    background: #4ba629;
    z-index: 198;
    border-radius: 76.19px;
  }
  .icon-app-crypto {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 21.905px;
    padding: 0px 0 0px 0;
    z-index: 199;
  }
  .icon-app-crypto-5e {
    flex-shrink: 0;
    position: relative;
    width: 21.905px;
    height: 26.667px;
    background: url(./assets/images/6acdc83c-ca1a-4c8a-8bbc-3dc1a6bacd55.png)
      no-repeat center;
    background-size: cover;
    z-index: 200;
    overflow: hidden;
  }
  .app-store {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 94px;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.52px;
    z-index: 201;
  }
  .iphones-5f {
    position: relative;
    width: 436.19px;
    height: 890.476px;
    margin: 38.097px 0 0 49.523px;
    z-index: 189;
  }
  .body-60 {
    position: absolute;
    width: 98.37%;
    height: 100.04%;
    top: 0;
    left: 0.87%;
    background: url(./assets/images/202cea22-3438-4346-add7-5ff05fa80f57.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 190;
  }
  .screen-replace-here-61 {
    position: absolute;
    width: 90.52%;
    height: 96.08%;
    top: 1.93%;
    left: 4.58%;
    background: url(./assets/images/bb8497e2a1b3fe14311c045c3332d7cb14c4e149.png)
      no-repeat center;
    background-size: cover;
    z-index: 196;
    border-radius: 50.714px;
  }
  .dinamy-island-62 {
    position: absolute;
    width: 26.61%;
    height: 3.85%;
    top: 3%;
    left: 36.86%;
    background: #131316;
    z-index: 197;
    border-radius: 49.806px;
  }
  .buttons-63 {
    position: absolute;
    width: 100.11%;
    height: 22.47%;
    top: 17.65%;
    left: 0;
    z-index: 191;
    border-radius: 1.446px;
  }
  .silence-button-64 {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 3.806px;
    height: 31.44px;
    margin: 0 0 0 0;
    background: #131316;
    z-index: -Infinity;
    border-radius: 1.446px;
  }
  .flex-column-f {
    position: absolute;
    width: 0.65%;
    height: 70.95%;
    top: 29.05%;
    left: 0.22%;
    z-index: 193;
  }
  .plus-button-65 {
    position: relative;
    width: 2.854px;
    height: 62.88px;
    margin: 0 0 0 0;
    background: #131316;
    z-index: 192;
    border-radius: 1.446px;
  }
  .plus-button-66 {
    position: relative;
    width: 2.854px;
    height: 62.88px;
    margin: 16.197px 0 0 0;
    background: #131316;
    z-index: 193;
    border-radius: 1.446px;
  }
  .power-button-67 {
    display: flex;
    align-items: flex-start;
    position: absolute;
    width: 0.87%;
    height: 49.05%;
    top: 50.48%;
    left: 99.13%;
    background: #131316;
    z-index: -Infinity;
    border-radius: 1.446px;
  }
  .android {
    flex-shrink: 0;
    position: relative;
    width: 536.19px;
    height: 738.095px;
    font-size: 0px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 202;
    overflow: hidden;
    border-radius: 38.095px;
  }
  .download-for-android {
    display: block;
    position: relative;
    height: 53px;
    margin: 45.715px 0 0 45.715px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 700;
    line-height: 52.4px;
    text-align: left;
    white-space: nowrap;
    z-index: 203;
  }
  .button-68 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 7.619px;
    position: relative;
    width: 193.476px;
    height: 66.667px;
    margin: 42.236px 0 0 45.715px;
    padding: 25.714px 30.476px 25.714px 30.476px;
    background: #4ba629;
    z-index: 204;
    border-radius: 76.19px;
  }
  .icon-app-crypto-69 {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 21.905px;
    padding: 0 0px 0 0px;
    z-index: 205;
  }
  .icon-app-crypto-6a {
    flex-shrink: 0;
    position: relative;
    width: 21.905px;
    height: 23.81px;
    background: url(./assets/images/8d9fd1cc-6d2c-407a-bbc5-f632484f8c83.png)
      no-repeat center;
    background-size: cover;
    z-index: 206;
    overflow: hidden;
  }
  .play-store {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 103px;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.52px;
    z-index: 207;
  }
  .samsung-galaxys {
    position: relative;
    width: 432.381px;
    height: 890.476px;
    margin: 38.095px 0 0 52.379px;
    background: url(./assets/images/5b00f1de-193a-40d9-927f-7cf400bffe19.png)
      no-repeat center;
    background-size: cover;
    z-index: 208;
    overflow: hidden;
  }
  .subscribe {
    position: relative;
    width: 100%;
    height: 264.762px;
    margin: 150.477px 0 0 0;
    background: #4ba629;
    z-index: 18;
  }
  .frame-6b {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 23.81px;
    position: absolute;
    width: 43.98%;
    height: 39.93%;
    top: 29.86%;
    left: 10.38%;
    z-index: 19;
  }
  .div-6c {
    flex-shrink: 0;
    position: relative;
    width: 64.762px;
    height: 64.762px;
    background: #ffffff;
    z-index: 20;
    overflow: hidden;
    border-radius: 19.048px;
  }
  .icon-newsletter-crypto {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 40px;
    margin: 15.238px 0 0 12.383px;
    z-index: 21;
  }
  .icon-newsletter-crypto-6d {
    flex-shrink: 0;
    position: relative;
    width: 40px;
    height: 35.238px;
    background: url(./assets/images/1f457cdd-aa45-4668-b884-789f09744ce2.png)
      no-repeat center;
    background-size: cover;
    z-index: 22;
    overflow: hidden;
  }
  .team-reach-out {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 544.762px;
    height: 105px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 38.095237731933594px;
    font-weight: 700;
    line-height: 52.4px;
    text-align: left;
    z-index: 23;
  }
  .input-email {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: absolute;
    width: 457.143px;
    height: 76.19px;
    top: 50%;
    left: 50%;
    padding: 7.619px 7.619px 7.619px 15.238px;
    background: #ffffff;
    border: 1.905px solid #ffffff;
    transform: translate(27.29%, -47.5%);
    z-index: 24;
    border-radius: 95.238px;
  }
  .enter-email-address {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #050505;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 25;
  }
  .frame-6e {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 133.333px;
    min-height: 0;
    background: #4ba629;
    z-index: 26;
    overflow: hidden;
    border-radius: 95.238px;
  }
  .share {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 50px;
    height: 16px;
    top: 21.857px;
    left: 40.762px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 13.333333015441895px;
    font-weight: 700;
    line-height: 15.238px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.52px;
    z-index: 27;  
  }
  .footer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    height: 366.667px;
    margin: 0px 0 0 0;
    padding: 53.333px 126.667px 30.476px 126.667px;
    background: rgba(255, 255, 255, 0.15);
    z-index: 2;
  }
  .group-6f {
    position: relative;
    width: 1188.418px;
    height: 278.056px;
    font-size: 0px;
    z-index: 3;
  }
  .menu {
    display: block;
    position: relative;
    height: 23.81px;
    margin: 0 0 0 3.098px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 17.14285659790039px;
    font-weight: 700;
    line-height: 23.81px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.86px;
    z-index: 7;
  }
  .flex-row-de {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 232.426px;
    height: 16px;
    margin: 62.856px 0 0 4.051px;
    z-index: 11;
  }
  .home-70 {
    flex-shrink: 0;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 13.333333015441895px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.33px;
    z-index: 8;
    text-decoration: none;
  }
  .mission-71 {
    flex-shrink: 0;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 13.333333015441895px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.33px;
    z-index: 11;
    text-decoration: none;
  }
  .flex-row-b {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 249.426px;
    height: 16px;
    margin: 16.381px 0 0 4.051px;
    z-index: 12;
  }
  .about-us-72 {
    flex-shrink: 0;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 13.333333015441895px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.33px;
    z-index: 9;
    text-decoration: none;
  }
  .products-73 {
    flex-shrink: 0;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 13.333333015441895px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.33px;
    z-index: 12;
    text-decoration: none;
  }
  .flex-row-ea {
    position: relative;
    width: 1184.366px;
    height: 34.718px;
    margin: 14.523px 0 0 4.051px;
    z-index: 17;
  }
  .our-pillars-74 {
    position: absolute;
    width: 156.038px;
    height: 34.718px;
    top: 0;
    left: 1028.328px;
    background: url(./assets/images/c570d38b-c705-40ae-848d-83cc96b02641.png)
      no-repeat center;
    background-size: cover;
    z-index: 17;
  }
  .contact-us-75 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    height: 16px;
    top: 1.857px;
    left: 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 13.333333015441895px;
    font-weight: 400;
    line-height: 15.24px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.33px;
    z-index: 10;
    text-decoration: none;
  }
  .flex-row-fbb {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    height: 16px;
    top: 1.857px;
    left: 165.426px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 13.333333015441895px;
    font-weight: 400;
    line-height: 15.24px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.33px;
    z-index: 13;
    text-decoration: none;
  }
  .all-rights-reserved {
    position: absolute;
    width: 25.259px;
    height: 25.259px;
    top: 2.244px;
    left: 1025.207px;
    background: url(./assets/images/848a2228-594d-46c3-8e70-8554a3abf130.png)
      no-repeat center;
    background-size: cover;
    z-index: 16;
  }
  .social-media-icons {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 1182.859px;
    height: 0.95px;
    margin: 38.561px 0 0 2.855px;
    background: rgba(255, 255, 255, 0.3);
    z-index: -Infinity;
  }
  .group-26 {
    position: relative;
    width: 1156.254px;
    height: 24.93px;
    margin: 29.323px 0 0 0;
    z-index: 15;
  }
  .text-5e {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 131.855px;
    height: 24.93px;
    bottom: 0;
    left: 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 15.2380952835083px;
    font-weight: 400;
    line-height: 24.762px;
    text-align: center;
    white-space: nowrap;
    z-index: 15;
  }
  .pic-14 {
    position: absolute;
    width: 19.048px;
    height: 19.048px;
    top: 2.826px;
    left: 1045.734px;
    background: url(./assets/images/3f503d1c-ac68-45d5-8392-fa6f5c0bf9fb.png)
      no-repeat center;
    background-size: cover;
    z-index: 4;
  }
  .pic-15 {
    position: absolute;
    width: 19.048px;
    height: 19.048px;
    top: 2.826px;
    left: 1091.449px;
    background: url(./assets/images/99b08867-7a19-448e-8f2f-a1fb0ddef2c6.png)
      no-repeat center;
    background-size: cover;
    z-index: 5;
  }
  .pic-16 {
    position: absolute;
    width: 19.09px;
    height: 19.048px;
    top: 2.826px;
    left: 1137.164px;
    background: url(./assets/images/88e06fff-83c3-4aae-8bf6-0527c4d5614b.png)
      no-repeat center;
    background-size: cover;
    z-index: 6;
  }
  .pic-17 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(./assets/images/d7a90687c73bfff985c5a1b0793e74d072beaff2.png)
      no-repeat center;
    background-size: cover;
  }
}

/* Code for mobile */
@media (max-width: 427px) {
  body {
    margin: 0;
  }
  :root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }
  html {
    scroll-behavior: smooth;
  }
  .popup {
    height: 100vh;
    width: 100vw;
    background: #4ba629;
    position: absolute;
    z-index: 99;
    overflow: hidden;
  }
  .main-container {
    overflow: hidden;
  }

  .main-container,
  .main-container * {
    box-sizing: border-box;
  }

  input,
  select,
  textarea,
  button {
    outline: 0;
  }

  .main-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 100%;
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: url(./assets/images/Mobile.png) no-repeat center;
    background-size: cover;
  }

  .popup-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .popup-content a {
    font-family: DM Sans, var(--default-font-family);
  }

  .close-btn {
    position: absolute;
    top: 27px;
    right: 42px;
    font-size: 24px;
    color: white;
    cursor: pointer;
}

  .close-btn img {
    width: 42px;
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    margin: 24px 0;
    transition: color 0.3s ease-in-out;
  }

  .flex-row-e {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 342px;
    height: 48px;
    margin: 24px auto 0;
    z-index: 91;
  }
  .logo-crypto-template {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 136px;
    z-index: 85;
  }
  .logo-crypto-template-1 {
    flex-shrink: 0;
    position: relative;
    width: 136px;
    height: 48px;
    z-index: 86;
  }
  .logo-crypto-template-2 {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 169.2px;
    margin: 3.824px 0 0 -0.1px;
    z-index: 87;
  }
  .header-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4.8px;
    position: relative;
    width: 184.8px;
    z-index: 88;
    overflow: hidden;
  }
  .logo {
    flex-shrink: 0;
    position: relative;
    width: 168.96px;
    height: 38.093px;
    z-index: 89;
    overflow: hidden;
  }
  .group {
    position: absolute;
    width: 200.54px;
    height: 43.745px;
    top: 0;
    left: 0;
    background: url(./assets/images/d223961e-f777-4d08-b7ef-ad339f5350d8.png)
      no-repeat center;
    background-size: cover;
    z-index: 90;
  }
  .menu {
    flex-shrink: 0;
    position: relative;
    width: 45px;
    height: 45px;
    background: #4ba629;
    z-index: 91;
    border-radius: 1000px;
  }
  .div {
    position: relative;
    width: 25px;
    height: 20px;
    margin: 13px 0 0 10px;
    background: url(./assets/images/06740c21-9278-4004-87cb-79568e4f36b6.png)
      no-repeat center;
    background-size: cover;
    z-index: 92;
  }
  .innovating-for-better-lives {
    position: relative;
    width: 257px;
    height: 92px;
    margin: 72px auto 0;
    font-family: DM Sans, var(--default-font-family);
    font-size: 38px;
    font-weight: 700;
    line-height: 46px;
    text-align: center;
    letter-spacing: -1px;
    z-index: 83;
  }
  .innovating {
    position: relative;
    color: #4ba629;
    font-family: DM Sans, var(--default-font-family);
    font-size: 38px;
    font-weight: 700;
    line-height: 46px;
    text-align: left;
    letter-spacing: -1px;
  }
  .for-better-lives {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 38px;
    font-weight: 700;
    line-height: 46px;
    text-align: left;
    letter-spacing: -1px;
  }
  .empowering-productivity-fostering-creativity-enhancing-safety {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 320px;
    height: 52px;
    margin: 16px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 84;
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 4px;
    position: relative;
    width: 358px;
    margin: 40px auto 0;
    padding: 27px 32px 27px 32px;
    background: #4ba629;
    z-index: 93;
    border-radius: 80px;
    height: 60px;
  }
  .lets-start {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 111px;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.6px;
    z-index: 94;
  }
  .apple-computers {
    position: relative;
    width: 358px;
    height: 215.685px;
    margin: 40px auto 0;
    background: url(./assets/images/mobile-apple.png) no-repeat center;
    background-size: cover;
    z-index: 95;
  }
  .download-app {
    position: relative;
    width: 100%;
    height: 674px;
    margin: 60.315px auto 0;
    background: #4ba629;
    z-index: 96;
    overflow: hidden;
  }
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    gap: 16px;
    position: relative;
    width: 334px;
    margin: 40px auto 0;
    z-index: 97;
  }
  .shaping-a-smarter-world-through-innovation {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    width: 334px;
    height: 72px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    z-index: 98;
  }
  .at-inventcare-we-specialize {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    width: 330px;
    height: 234px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 99;
  }
  .group-3 {
    position: relative;
    width: 297.5px;
    height: 319.579px;
    margin: 32px auto 0;
    background: url(./assets/images/2a6a1920-1b08-4b48-afed-65613551e04b.png)
      no-repeat center;
    background-size: cover;
    z-index: 100;
  }
  .heading-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    gap: 16px;
    position: relative;
    width: 316px;
    margin: 60px auto 0;
    z-index: 51;
  }
  .our-pillars-of-innovation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 316px;
    height: 36px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    white-space: nowrap;
    z-index: 52;
  }
  .driving-meaningful-progress-transformative-solutions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 240px;
    height: 78px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 53;
  }
  .blog-card-mobile {
    position: relative;
    width: 358.643px;
    height: 484px;
    margin: 32px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 54;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-5 {
    position: absolute;
    width: 382px;
    height: 215px;
    top: 0;
    left: 0;
    z-index: 55;
    overflow: hidden;
  }
  .image-blog-crypto-template-p {
    position: absolute;
    height: 215px;
    top: 50%;
    right: 0;
    left: 0;
    background: url(./assets/images/mobile-book.png) no-repeat center;
    background-size: cover;
    transform: translate(0, -50%);
    z-index: 56;
  }
  .div-6 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    position: absolute;
    width: 204px;
    height: 55px;
    top: 189px;
    left: 50%;
    padding: 14px 22px 14px 22px;
    background: #4ba629;
    transform: translate(-51.63%, 0);
    z-index: 59;
    border-radius: 1500px;
  }
  .productivity {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 27px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.05px;
    z-index: 60;
  }
  .div-7 {
    position: absolute;
    height: 55.58%;
    top: 44.42%;
    right: 7.643px;
    left: 0;
    z-index: 57;
  }
  .simplifying-tasks-amplifying-impact {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 303px;
    height: 67.66%;
    top: 19.7%;
    right: 24px;
    left: 24px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 58;
  }
  .blog-card-mobile-8 {
    position: relative;
    width: 358px;
    height: 484px;
    margin: 16px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 61;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-9 {
    position: absolute;
    width: 382px;
    height: 215px;
    top: 0;
    left: 0;
    z-index: 62;
    overflow: hidden;
  }
  .image-blog-crypto-template-p-a {
    position: absolute;
    height: 215px;
    top: 50%;
    right: 0;
    left: 0;
    background: url(./assets/images/2mob.png) no-repeat center;
    background-size: cover;
    transform: translate(0, -50%);
    z-index: 63;
  }
  .div-b {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    position: absolute;
    width: 154px;
    height: 51px;
    top: 189px;
    left: 50%;
    padding: 14px 22px 14px 22px;
    background: #4ba629;
    transform: translate(-50%, 0);
    z-index: 66;
    border-radius: 1500px;
  }
  .learning {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 23px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16.94769287109375px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.89px;
    z-index: 67;
  }
  .div-c {
    position: absolute;
    height: 55.58%;
    top: 44.42%;
    right: 0;
    left: 0;
    z-index: 64;
  }
  .creating-platforms-foster-engagement {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 310px;
    height: 67.66%;
    top: 19.7%;
    right: 24px;
    left: 24px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 65;
  }
  .blog-card-mobile-d {
    position: relative;
    width: 358px;
    height: 484px;
    margin: 16px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 68;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-e {
    position: absolute;
    width: 382px;
    height: 215px;
    top: 0;
    left: 0;
    z-index: 69;
    overflow: hidden;
  }
  .image-blog-crypto-template-p-f {
    position: absolute;
    height: 215px;
    top: 50%;
    right: 0;
    left: 0;
    background: url(./assets/images/3mob.png) no-repeat center;
    background-size: cover;
    transform: translate(0, -50%);
    z-index: 70;
  }
  .div-10 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    position: absolute;
    width: 167px;
    height: 51px;
    top: 189px;
    left: 50%;
    padding: 14px 22px 14px 22px;
    background: #4ba629;
    transform: translate(-49.7%, 0);
    z-index: 73;
    border-radius: 1500px;
  }
  .creativity {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 23px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16.94769287109375px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.89px;
    z-index: 74;
  }
  .div-11 {
    position: absolute;
    height: 55.58%;
    top: 44.42%;
    right: 0;
    left: 0;
    z-index: 71;
  }
  .encouraging-self-expression {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 310px;
    height: 67.66%;
    top: 19.7%;
    right: 24px;
    left: 24px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 72;
  }
  .blog-card-mobile-12 {
    position: relative;
    width: 358px;
    height: 484px;
    margin: 16px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 75;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-13 {
    position: absolute;
    width: 382px;
    height: 215px;
    top: 0;
    left: 0;
    z-index: 76;
    overflow: hidden;
  }
  .image-blog-crypto-template {
    position: absolute;
    height: 215px;
    top: 50%;
    right: 0;
    left: 0;
    background: url(./assets/images/4mob.png) no-repeat center;
    background-size: cover;
    transform: translate(0, -50%);
    z-index: 77;
  }
  .div-14 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    position: absolute;
    width: 130px;
    height: 51px;
    top: 189px;
    left: 50%;
    padding: 14px 22px 14px 22px;
    background: #4ba629;
    transform: translate(-50%, 0);
    z-index: 80;
    border-radius: 1500px;
  }
  .safety {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 23px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16.94769287109375px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.89px;
    z-index: 81;
  }
  .div-15 {
    position: absolute;
    height: 55.58%;
    top: 44.42%;
    right: 0;
    left: 0;
    z-index: 78;
  }
  .well-being-safety-solutions {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 310px;
    height: 57.99%;
    top: 21.19%;
    right: 24px;
    left: 24px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 79;
  }
  .download-app-16 {
    position: relative;
    width: 100%;
    height: 1619px;
    margin: 60px auto 0;
    background: #4ba629;
    z-index: 101;
    overflow: hidden;
  }
  .inventcare-products {
    position: relative;
    width: 305px;
    height: 108px;
    margin: 40px auto 0;
    font-family: DM Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    z-index: 102;
  }
  .at {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
  }
  .inventcare {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
  }
  .creating-products {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  }
  .at-17 {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
  }
  .group-18 {
    position: relative;
    width: 236px;
    height: 217.3px;
    margin: 16.699px auto 0;
    z-index: 105;
    overflow: visible auto;
    border-radius: 36.4px;
  }
  .div-19 {
    position: relative;
    width: 128.8px;
    height: 128.8px;
    margin: 0 0 0 53.699px;
    background: #4ba629;
    z-index: 106;
    overflow: hidden;
    border-radius: 36.4px;
  }
  .icon-spec-crypto-template {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 78.4px;
    margin: 19.598px 0 0 25.199px;
    z-index: 107;
  }
  .icon-spec-crypto-template-1a {
    flex-shrink: 0;
    position: relative;
    width: 78.4px;
    height: 92.4px;
    background: url(./assets/images/d3481db3-7a20-4b32-8f29-3773ededa699.png)
      no-repeat center;
    background-size: cover;
    z-index: 108;
    overflow: hidden;
  }
  .boost-productivity {
    display: block;
    position: relative;
    height: 23px;
    margin: 8.501px 0 0 40px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16.799999237060547px;
    font-weight: 900;
    line-height: 22.403px;
    text-align: left;
    white-space: nowrap;
    z-index: 109;
  }
  .empowering-tools {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 236px;
    height: 45px;
    margin: 12px 0 0 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 22.403px;
    text-align: center;
    z-index: 110;
  }
  .group-1b {
    position: relative;
    width: 236px;
    height: 217.3px;
    margin: 17.001px auto 0;
    z-index: 111;
    overflow: visible auto;
    border-radius: 36.4px;
  }
  .div-1c {
    position: relative;
    width: 128.8px;
    height: 128.8px;
    margin: 0 0 0 53.699px;
    background: #4ba629;
    z-index: 112;
    overflow: hidden;
    border-radius: 36.4px;
  }
  .icon-spec-crypto-template-1d {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 78.4px;
    margin: 19.598px 0 0 25.199px;
    z-index: 113;
  }
  .icon-spec-crypto-template-1e {
    flex-shrink: 0;
    position: relative;
    width: 78.4px;
    height: 92.4px;
    background: url(./assets/images/6ffbd505-6c54-4006-b303-8af040cb70ea.png)
      no-repeat center;
    background-size: cover;
    z-index: 114;
    overflow: hidden;
  }
  .foster-creativity {
    display: block;
    position: relative;
    height: 23px;
    margin: 8.501px 0 0 48px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16.799999237060547px;
    font-weight: 900;
    line-height: 22.403px;
    text-align: left;
    white-space: nowrap;
    z-index: 115;
  }
  .unlocking-potential {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 236px;
    height: 45px;
    margin: 12px 0 0 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 22.403px;
    text-align: center;
    z-index: 116;
  }
  .group-1f {
    position: relative;
    width: 236px;
    height: 217.3px;
    margin: 16.7px auto 0;
    z-index: 117;
    overflow: visible auto;
    border-radius: 36.4px;
  }
  .div-20 {
    position: relative;
    width: 128.8px;
    height: 128.8px;
    margin: 0 0 0 53.699px;
    background: #4ba629;
    z-index: 118;
    overflow: hidden;
    border-radius: 36.4px;
  }
  .icon-spec-crypto-template-21 {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 78.4px;
    margin: 19.598px 0 0 25.199px;
    z-index: 119;
  }
  .icon-spec-crypto-template-22 {
    flex-shrink: 0;
    position: relative;
    width: 78.4px;
    height: 92.4px;
    background: url(./assets/images/b13031d8-12ca-42cb-ab08-195d7a068566.png)
      no-repeat center;
    background-size: cover;
    z-index: 120;
    overflow: hidden;
  }
  .track-progress {
    display: block;
    position: relative;
    height: 23px;
    margin: 8.501px 0 0 56px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16.799999237060547px;
    font-weight: 900;
    line-height: 22.403px;
    text-align: left;
    white-space: nowrap;
    z-index: 121;
  }
  .helping-measure-growth {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 236px;
    height: 45px;
    margin: 12px 0 0 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 22.403px;
    text-align: center;
    z-index: 122;
  }
  .group-23 {
    position: relative;
    width: 236px;
    height: 217.3px;
    margin: 16.7px auto 0;
    z-index: 123;
    overflow: visible auto;
    border-radius: 36.4px;
  }
  .div-24 {
    position: relative;
    width: 128.8px;
    height: 128.8px;
    margin: 0 0 0 53.699px;
    background: #4ba629;
    z-index: 124;
    overflow: hidden;
    border-radius: 36.4px;
  }
  .icon-spec-crypto-template-25 {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 78.4px;
    margin: 19.598px 0 0 25.199px;
    z-index: 125;
  }
  .icon-spec-crypto-template-26 {
    flex-shrink: 0;
    position: relative;
    width: 78.4px;
    height: 92.4px;
    background: url(./assets/images/3856ad3f-03cb-47e4-b96a-ea8f6afd1dce.png)
      no-repeat center;
    background-size: cover;
    z-index: 126;
    overflow: hidden;
  }
  .ensure-safety {
    display: block;
    position: relative;
    height: 23px;
    margin: 8.501px 0 0 60px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16.799999237060547px;
    font-weight: 900;
    line-height: 22.403px;
    text-align: left;
    white-space: nowrap;
    z-index: 127;
  }
  .keeping-secure {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 236px;
    height: 45px;
    margin: 12px 0 0 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 22.403px;
    text-align: center;
    z-index: 128;
  }
  .group-27 {
    position: relative;
    width: 236px;
    height: 217.3px;
    margin: 16.7px auto 0;
    z-index: 129;
    overflow: visible auto;
    border-radius: 36.4px;
  }
  .div-28 {
    position: relative;
    width: 128.8px;
    height: 128.8px;
    margin: 0 0 0 53.699px;
    background: #4ba629;
    z-index: 130;
    overflow: hidden;
    border-radius: 36.4px;
  }
  .icon-spec-crypto-template-29 {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 78.4px;
    margin: 19.598px 0 0 25.199px;
    z-index: 131;
  }
  .icon-spec-crypto-template-2a {
    flex-shrink: 0;
    position: relative;
    width: 78.4px;
    height: 92.4px;
    background: url(./assets/images/961d8c63-f839-4d82-bc59-0b814c24f931.png)
      no-repeat center;
    background-size: cover;
    z-index: 132;
    overflow: hidden;
  }
  .quality-digital-life {
    display: block;
    position: relative;
    height: 23px;
    margin: 8.501px 0 0 31px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16.799999237060547px;
    font-weight: 900;
    line-height: 22.403px;
    text-align: left;
    white-space: nowrap;
    z-index: 133;
  }
  .balanced-technology-use {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 236px;
    height: 45px;
    margin: 12px 0 0 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 22.403px;
    text-align: center;
    z-index: 134;
  }
  .group-2b {
    position: relative;
    width: 297.497px;
    height: 319.576px;
    margin: 39.7px auto 0;
    z-index: 103;
  }
  .group-2c {
    position: absolute;
    width: 297.497px;
    height: 319.576px;
    top: 50%;
    left: 50%;
    background: url(./assets/images/f50b411c-e1a0-4fc5-a851-3931b8f2bc0b.png)
      no-repeat center;
    background-size: cover;
    transform: translate(-50%, -50%);
    z-index: 104;
  }
  .rectangle {
    position: relative;
    width: 185.014px;
    height: 49.175px;
    margin: 32.146px 0 0 0.13px;
    background: url(./assets/images/28ea8efb-3f1d-464f-960c-78ca2c7ee683.png)
      no-repeat center;
    background-size: cover;
    z-index: 173;
  }
  .products {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 111px;
    height: 36px;
    top: 6.4px;
    left: 16.801px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 20.48000144958496px;
    font-weight: 900;
    line-height: 35.213px;
    text-align: center;
    white-space: nowrap;
    z-index: 173;
  }
  .champsverse {
    display: block;
    position: relative;
    height: 36px;
    margin: 32.679px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    white-space: nowrap;
    z-index: 136;
  }
  .transforming-learning {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 315px;
    height: 78px;
    margin: 16px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 137;
  }
  .div-2d {
    position: relative;
    width: 358px;
    height: 295px;
    margin: 32px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 138;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-2e {
    position: relative;
    width: 68px;
    height: 68px;
    margin: 48px 0 0 145px;
    background: #4ba629;
    z-index: 139;
    overflow: hidden;
    border-radius: 20px;
  }
  .student-center {
    position: relative;
    width: 48px;
    height: 48px;
    margin: 10px 0 0 10px;
    background: url(./assets/images/a51f5ff074d3b6f466bd34260bf31ef39f514aa2.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 140;
  }
  .learn-grow {
    display: block;
    position: relative;
    height: 26px;
    margin: 20px 0 0 105px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 25.992px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.9px;
    z-index: 141;
  }
  .safe-fun-space {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 279px;
    height: 78px;
    margin: 7px 0 0 40px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 142;
  }
  .div-2f {
    position: relative;
    width: 358px;
    height: 295px;
    margin: 16px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 143;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-30 {
    position: relative;
    width: 68px;
    height: 68px;
    margin: 48px 0 0 145px;
    background: #4ba629;
    z-index: 144;
    overflow: hidden;
    border-radius: 20px;
  }
  .safety-care {
    position: relative;
    width: 48px;
    height: 48px;
    margin: 11px 0 0 9px;
    background: url(./assets/images/a3be43486c5a3ce16a972333f888f116b287c2aa.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 145;
  }
  .safety-well-being {
    display: block;
    position: relative;
    height: 26px;
    margin: 20px 0 0 71px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 25.992px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.9px;
    z-index: 146;
  }
  .safe-digital-space {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 271px;
    height: 78px;
    margin: 7px 0 0 44px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 147;
  }
  .ios {
    position: relative;
    width: 358px;
    height: 622px;
    margin: 16px auto 0;
    font-size: 0px;
    background: #4ba629;
    z-index: 148;
    overflow: hidden;
    border-radius: 40px;
  }
  .ios-android-app {
    display: block;
    position: relative;
    height: 26px;
    margin: 44px 0 0 75px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1px;
    z-index: 149;
  }
  .android-ios-platforms {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 259px;
    height: 78px;
    margin: 18px 0 0 50px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 150;
  }
  .iphones {
    position: relative;
    width: 285.452px;
    height: 583px;
    margin: 28px 0 0 36px;
    z-index: 151;
  }
  .div-31 {
    position: absolute;
    width: 98.46%;
    height: 100.05%;
    top: 0;
    left: 0.85%;
    background: url(./assets/images/115e494b-0e30-4e16-9b6c-8d5176e5d764.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 152;
  }
  .screen-replace-here {
    position: absolute;
    width: 90.56%;
    height: 96.04%;
    top: 1.94%;
    left: 4.8%;
    background: url(./assets/images/bb8497e2a1b3fe14311c045c3332d7cb14c4e149.png)
      no-repeat center;
    background-size: cover;
    z-index: 158;
    border-radius: 33.198px;
  }
  .dinamy-island {
    position: absolute;
    width: 26.52%;
    height: 3.74%;
    top: 3.04%;
    left: 36.67%;
    background: #131316;
    z-index: 159;
    border-radius: 32.604px;
  }
  .buttons {
    position: absolute;
    width: 100.15%;
    height: 22.56%;
    top: 17.57%;
    left: 0;
    z-index: 153;
    border-radius: 0.95px;
  }
  .flex-column-c {
    position: absolute;
    width: 0.85%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 156;
  }
  .silence-button {
    position: relative;
    width: 2.416px;
    height: 20.976px;
    margin: 0 0 0 0;
    background: #131316;
    z-index: 156;
    border-radius: 0.95px;
  }
  .section-e {
    position: relative;
    width: 1.611px;
    height: 41.145px;
    margin: 17.749px 0 0 0;
    background: #131316;
    z-index: 154;
    border-radius: 0.95px;
  }
  .wrapper-12 {
    position: relative;
    width: 1.611px;
    height: 41.145px;
    margin: 10.488px 0 0 0;
    background: #131316;
    z-index: 155;
    border-radius: 0.95px;
  }
  .power-button {
    display: flex;
    align-items: flex-start;
    position: absolute;
    width: 0.85%;
    height: 49.08%;
    top: 50.31%;
    left: 99.16%;
    background: #131316;
    z-index: -Infinity;
    border-radius: 0.95px;
  }
  .div-32 {
    position: relative;
    width: 358px;
    height: 295px;
    margin: 16px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 160;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-33 {
    position: relative;
    width: 68px;
    height: 68px;
    margin: 48px 0 0 145px;
    background: #4ba629;
    z-index: 161;
    overflow: hidden;
    border-radius: 20px;
  }
  .bright-mind {
    position: relative;
    width: 48px;
    height: 48px;
    margin: 10px 0 0 10px;
    background: url(./assets/images/4478c8465ba442557fef64971893da2bcfea5a95.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 162;
  }
  .creativity-34 {
    display: block;
    position: relative;
    height: 26px;
    margin: 20px 0 0 124px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 25.992px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.9px;
    z-index: 163;
  }
  .young-artists-express {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 279px;
    height: 78px;
    margin: 7px 0 0 40px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 164;
  }
  .div-35 {
    position: relative;
    width: 358px;
    height: 295px;
    margin: 16px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 165;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-36 {
    position: relative;
    width: 68px;
    height: 68px;
    margin: 48px 0 0 145px;
    background: #4ba629;
    z-index: 166;
    overflow: hidden;
    border-radius: 20px;
  }
  .eaeefba-icon-section-about-crypto-template {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    width: 58px;
    height: 47px;
    top: 50%;
    left: 50%;
    padding: 0;
    transform: translate(-50%, -51.06%);
    z-index: 167;
  }
  .guardian {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 50%;
    left: 50%;
    background: url(./assets/images/46af49cb2aa960bbd5b420b803958dd7d8f36d3f.png)
      no-repeat center;
    background-size: contain;
    transform: translate(-50%, -50%);
    opacity: 0.8;
    z-index: 168;
  }
  .parental-tools {
    display: block;
    position: relative;
    height: 26px;
    margin: 20px 0 0 97px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 25.992px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.9px;
    z-index: 169;
  }
  .easy-to-use-features {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 279px;
    height: 78px;
    margin: 7px 0 0 40px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 170;
  }
  .champsverse-iot {
    display: block;
    position: relative;
    height: 36px;
    margin: 60px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    white-space: nowrap;
    z-index: 2;
  }
  .revolutionizing-childrens-play {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 287px;
    height: 78px;
    margin: 16px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 3;
  }
  .div-37 {
    position: relative;
    width: 358px;
    height: 295px;
    margin: 32px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 4;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-38 {
    position: relative;
    width: 68px;
    height: 68px;
    margin: 48px auto 0;
    background: #4ba629;
    z-index: 5;
    overflow: hidden;
    border-radius: 20px;
  }
  .fast-forward {
    position: relative;
    width: 48px;
    height: 48px;
    margin: 10px 0 0 10px;
    background: url(./assets/images/1dda445150e7c58aa5eb264bafa79cfcc9297ce8.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 6;
  }
  .next-gen-toy-design {
    display: block;
    position: relative;
    height: 26px;
    margin: 20px 0 0 69px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 25.992px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.9px;
    z-index: 7;
  }
  .developing-cutting-edge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 279px;
    height: 78px;
    margin: 7px 0 0 40px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 8;
  }
  .div-39 {
    position: relative;
    width: 358px;
    height: 295px;
    margin: 16px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 9;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-3a {
    position: relative;
    width: 68px;
    height: 68px;
    margin: 48px 0 0 145px;
    background: #4ba629;
    z-index: 10;
    overflow: hidden;
    border-radius: 20px;
  }
  .ping-pong {
    position: relative;
    width: 48px;
    height: 48px;
    margin: 10px 0 0 10px;
    background: url(./assets/images/d0ee65febd96cfcdd8ea484a7115e46817aab03e.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 11;
  }
  .engaging-fun-games {
    display: block;
    position: relative;
    height: 26px;
    margin: 20px 0 0 46px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 25.992px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.9px;
    z-index: 12;
  }
  .creating-interactive-games {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 279px;
    height: 78px;
    margin: 7px 0 0 40px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 13;
  }
  .ios-3b {
    position: relative;
    width: 358px;
    height: 622px;
    margin: 16px auto 0;
    background: url(./assets/images/f7f77aa57179dfe44dd3eecdf472a9cfd8aac798.png)
      no-repeat center;
    background-size: cover;
    z-index: 24;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-3c {
    position: relative;
    width: 358px;
    height: 295px;
    margin: 16px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 14;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-3d {
    position: relative;
    width: 68px;
    height: 68px;
    margin: 48px 0 0 145px;
    background: #4ba629;
    z-index: 15;
    overflow: hidden;
    border-radius: 20px;
  }
  .internet {
    position: relative;
    width: 48px;
    height: 48px;
    margin: 10px 0 0 10px;
    background: url(./assets/images/23e9748bf5692e4b86582e0306cecd0bf6605b4a.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 16;
  }
  .reduced-online-time {
    display: block;
    position: relative;
    height: 26px;
    margin: 20px 0 0 71px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 25.992px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.9px;
    z-index: 17;
  }
  .offline-entertainment-options {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 279px;
    height: 78px;
    margin: 7px 0 0 40px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 18;
  }
  .div-3e {
    position: relative;
    width: 358px;
    height: 295px;
    margin: 16px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 19;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-3f {
    position: relative;
    width: 68px;
    height: 68px;
    margin: 48px 0 0 145px;
    background: #4ba629;
    z-index: 20;
    overflow: hidden;
    border-radius: 20px;
  }
  .development-skill {
    position: relative;
    width: 48px;
    height: 48px;
    margin: 10px 0 0 10px;
    background: url(./assets/images/55ae2f6c1bb34ea7f2b458896944556d6b9d8e64.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 21;
  }
  .enhanced-development {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 251px;
    height: 26px;
    margin: 20px 0 0 54px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 25.992px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.9px;
    z-index: 22;
  }
  .interactive-gaming-experiences {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 279px;
    height: 78px;
    margin: 7px 0 0 40px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 23;
  }
  .inventcare-safety-solutions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 273px;
    height: 72px;
    margin: 50px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    z-index: 26;
  }
  .innovative-safety-solutions {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 314px;
    height: 52px;
    margin: 16px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 27;
  }
  .div-40 {
    position: relative;
    width: 358px;
    height: 295px;
    margin: 32px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 28;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-41 {
    position: relative;
    width: 68px;
    height: 68px;
    margin: 48px 0 0 145px;
    background: #4ba629;
    z-index: 29;
    overflow: hidden;
    border-radius: 20px;
  }
  .protect {
    position: relative;
    width: 48px;
    height: 48px;
    margin: 10px 0 0 10px;
    background: url(./assets/images/5a25c34f43aa7fd3b353d7bb98f8df7e4a44b691.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 30;
  }
  .ensuring-safety {
    display: block;
    position: relative;
    height: 26px;
    margin: 20px 0 0 92px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 25.992px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.9px;
    z-index: 31;
  }
  .well-being-security {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 248px;
    height: 78px;
    margin: 7px 0 0 55px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 32;
  }
  .div-42 {
    position: relative;
    width: 358px;
    height: 295px;
    margin: 16px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 33;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-43 {
    position: relative;
    width: 68px;
    height: 68px;
    margin: 48px 0 0 145px;
    background: #4ba629;
    z-index: 34;
    overflow: hidden;
    border-radius: 20px;
  }
  .eaefefbb-icon-section-about-crypto-template {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 48px;
    margin: 9.5px 0 0 9.5px;
    padding: 0 0px 0 0px;
    z-index: 35;
  }
  .private-wall-mount-camera {
    flex-shrink: 0;
    position: relative;
    width: 48px;
    height: 48px;
    background: url(./assets/images/b70b6909ba281183d503239b729be6db6f740108.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 36;
  }
  .advanced-technology {
    display: block;
    position: relative;
    height: 26px;
    margin: 20px 0 0 58px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 25.992px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.9px;
    z-index: 37;
  }
  .safety-convenience {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 279px;
    height: 78px;
    margin: 7px 0 0 40px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 38;
  }
  .ios-44 {
    position: relative;
    width: 358px;
    height: 622px;
    margin: 16px auto 0;
    background: url(./assets/images/fcbdbd1f-c2fe-4135-9c15-da9af399829c.png)
      no-repeat center;
    background-size: cover;
    z-index: 49;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-45 {
    position: relative;
    width: 358px;
    height: 295px;
    margin: 16px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 39;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-46 {
    position: relative;
    width: 68px;
    height: 68px;
    margin: 48px 0 0 145px;
    background: #4ba629;
    z-index: 40;
    overflow: hidden;
    border-radius: 20px;
  }
  .processor {
    position: relative;
    width: 44px;
    height: 44px;
    margin: 12px 0 0 12px;
    background: url(./assets/images/d206df11311ffcc16675c8939a8aa2cb8ddcdc06.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 41;
  }
  .surveillance {
    display: block;
    position: relative;
    height: 26px;
    margin: 20px 0 0 108px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 25.992px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.9px;
    z-index: 42;
  }
  .awareness-protection {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 279px;
    height: 78px;
    margin: 7px 0 0 40px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 43;
  }
  .div-47 {
    position: relative;
    width: 358px;
    height: 295px;
    margin: 16px auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 44;
    overflow: hidden;
    border-radius: 40px;
  }
  .div-48 {
    position: relative;
    width: 68px;
    height: 68px;
    margin: 48px 0 0 145px;
    background: #4ba629;
    z-index: 45;
    overflow: hidden;
    border-radius: 20px;
  }
  .male-user {
    position: relative;
    width: 48px;
    height: 48px;
    margin: 10.266px 0 0 10px;
    background: url(./assets/images/a77954abbdc5359c3b398aaed29b9aa4ca9d3b49.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 46;
  }
  .user-friendly-access {
    display: block;
    position: relative;
    height: 26px;
    margin: 20px 0 0 64px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 25.992px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.9px;
    z-index: 47;
  }
  .intuitive-solutions {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 279px;
    height: 78px;
    margin: 7px 0 0 40px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 48;
  }
  .download-champsverse {
    display: block;
    position: relative;
    height: 36px;
    margin: 60px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    white-space: nowrap;
    z-index: 176;
  }
  .lorem-ipsum {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 330px;
    height: 78px;
    margin: 16px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 177;
  }
  .ios-49 {
    position: relative;
    width: 358px;
    height: 580px;
    margin: 32px auto 0;
    font-size: 0px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 178;
    overflow: hidden;
    border-radius: 40px;
  }
  .download-for-ios {
    display: block;
    position: relative;
    height: 36px;
    margin: 32px 0 0 63px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    white-space: nowrap;
    z-index: 179;
  }
  .ios-4a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 8px;
    position: relative;
    width: 193px;
    height: 70px;
    margin: 32px 0 0 83px;
    padding: 27px 32px 27px 32px;
    background: #4ba629;
    z-index: 189;
    border-radius: 80px;
  }
  .eaeeeefbdc-icon-app-crypto-template {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 23px;
    padding: 0px 0 0px 0;
    z-index: 190;
  }
  .eaeeeefbdc-icon-app-crypto-template-4b {
    flex-shrink: 0;
    position: relative;
    width: 23px;
    height: 28px;
    background: url(./assets/images/ca43220a-9e77-43b8-9070-a699e85fea24.png)
      no-repeat center;
    background-size: cover;
    z-index: 191;
    overflow: hidden;
  }
  .download-app-4c {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 99px;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.6px;
    z-index: 192;
  }
  .iphones-4d {
    position: relative;
    width: 284.597px;
    height: 581px;
    margin: 40px 0 0 37px;
    z-index: 180;
  }
  .body {
    position: absolute;
    width: 98.37%;
    height: 100.04%;
    top: 0;
    left: 0.87%;
    background: url(./assets/images/433421cd-5087-4a5a-82b1-be9dcd3f530c.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 181;
  }
  .screen-replace-here-4e {
    position: absolute;
    width: 90.52%;
    height: 96.08%;
    top: 1.93%;
    left: 4.58%;
    background: url(./assets/images/bb8497e2a1b3fe14311c045c3332d7cb14c4e149.png)
      no-repeat center;
    background-size: cover;
    z-index: 187;
    border-radius: 33.089px;
  }
  .dinamy-island-4f {
    position: absolute;
    width: 26.61%;
    height: 3.85%;
    top: 3%;
    left: 36.86%;
    background: #1b1b1f;
    z-index: 188;
    border-radius: 32.497px;
  }
  .buttons-50 {
    position: absolute;
    width: 100.11%;
    height: 22.47%;
    top: 17.65%;
    left: 0;
    z-index: 182;
    border-radius: 0.94px;
  }
  .silence-button-51 {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 2.483px;
    height: 20.513px;
    margin: 0 0 0 0;
    background: #1b1b1f;
    z-index: -Infinity;
    border-radius: 0.94px;
  }
  .flex-column-a {
    position: absolute;
    width: 0.65%;
    height: 70.95%;
    top: 29.05%;
    left: 0.22%;
    z-index: 184;
  }
  .plus-button {
    position: relative;
    width: 1.862px;
    height: 41.026px;
    margin: 0 0 0 0;
    background: #1b1b1f;
    z-index: 183;
    border-radius: 0.94px;
  }
  .button-52 {
    position: relative;
    width: 1.862px;
    height: 41.026px;
    margin: 10.567px 0 0 0;
    background: #1b1b1f;
    z-index: 184;
    border-radius: 0.94px;
  }
  .power-button-53 {
    display: flex;
    align-items: flex-start;
    position: absolute;
    width: 0.87%;
    height: 49.05%;
    top: 50.48%;
    left: 99.13%;
    background: #1b1b1f;
    z-index: -Infinity;
    border-radius: 0.94px;
  }
  .android {
    position: relative;
    width: 358px;
    height: 580px;
    margin: 20px auto 0;
    font-size: 0px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 193;
    overflow: hidden;
    border-radius: 40px;
  }
  .download-android {
    display: block;
    position: relative;
    height: 36px;
    margin: 32px 0 0 32px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    white-space: nowrap;
    z-index: 194;
  }
  .button-54 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 8px;
    position: relative;
    width: 203px;
    height: 70px;
    margin: 32px 0 0 77.5px;
    padding: 27px 32px 27px 32px;
    background: #4ba629;
    z-index: 195;
    border-radius: 80px;
  }
  .icon-app-crypto {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 23px;
    padding: 0 0px 0 0px;
    z-index: 196;
  }
  .icon-app-crypto-55 {
    flex-shrink: 0;
    position: relative;
    width: 23px;
    height: 25px;
    background: url(./assets/images/4271dc2f-4ac4-41d4-87e3-124ca1aa133e.png)
      no-repeat center;
    background-size: cover;
    z-index: 197;
    overflow: hidden;
  }
  .play-store {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 108px;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.6px;
    z-index: 198;
  }
  .samsung-galaxys {
    position: relative;
    width: 282.111px;
    height: 581px;
    margin: 40px 0 0 38px;
    background: url(./assets/images/f86388b7-1ac8-4c47-820d-4e858c679102.png)
      no-repeat center;
    background-size: cover;
    z-index: 199;
    overflow: hidden;
  }
  .frame {
    position: relative;
    width: 100%;
    height: 521px;
    margin: 60px auto 0;
    background: #4ba629;
    z-index: 221;
    overflow: hidden;
  }
  .div-56 {
    position: relative;
    width: 68px;
    height: 68px;
    margin: 64px 0 0 161px;
    background: #ffffff;
    z-index: 222;
    overflow: hidden;
    border-radius: 20px;
  }
  .icon-newsletter-crypto {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 42px;
    margin: 16px 0 0 13px;
    z-index: 223;
  }
  .icon-newsletter-crypto-57 {
    flex-shrink: 0;
    position: relative;
    width: 42px;
    height: 37px;
    background: url(./assets/images/d54cae50-5f53-4816-8d0a-2abc9951ee80.png)
      no-repeat center;
    background-size: cover;
    z-index: 224;
    overflow: hidden;
  }
  .share-email {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 276px;
    height: 108px;
    margin: 24px auto 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    z-index: 225;
  }
  .input-email {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    width: 358px;
    height: 80px;
    margin: 24px auto 0;
    padding: 8px 8px 8px 25px;
    background: #ffffff;
    border: 2px solid #ffffff;
    z-index: 228;
    border-radius: 100px;
  }
  .enter-email-address {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 26px;
    color: #000625;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    white-space: nowrap;
    z-index: 229;
  }
  .button-58 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 4px;
    position: relative;
    width: 354px;
    height: 70px;
    margin: 20px auto 0;
    padding: 27px 32px 27px 32px;
    background: #050505;
    z-index: 226;
    border-radius: 80px;
  }
  .button-59 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 59px;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.6px;
    z-index: 227;
  }
  .footer-mobile {
    position: relative;
    width: 100%;
    height: 301px;
    margin: 0 auto 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 200;
    overflow: hidden;
  }
  .group-5a {
    position: absolute;
    width: 342px;
    height: 248.992px;
    top: 20.008px;
    left: 24px;
    z-index: 201;
  }
  .flex-row-dce {
    position: relative;
    width: 342px;
    height: 56px;
    margin: 0 0 0 0;
    z-index: 215;
  }
  .div-5b {
    position: absolute;
    width: 341.531px;
    height: 56px;
    top: 0;
    left: 0.23px;
    z-index: 206;
  }
  .menu-5c {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    height: 25px;
    top: 9px;
    left: 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.9px;
    z-index: 207;
  }
  .div-5d {
    position: absolute;
    height: 1px;
    top: 50%;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    transform: translate(0, 2699.22%);
    z-index: 215;
  }
  .flex-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 227px;
    height: 16px;
    margin: 31.992px 0 0 0;
    z-index: 211;
  }
  .home {
    flex-shrink: 0;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.21px;
    z-index: 208;
    text-decoration: none;
  }
  .mission {
    flex-shrink: 0;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.21px;
    z-index: 211;
  }
  .flex-row-cf {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 244px;
    height: 16px;
    margin: 16px 0 0 0;
    z-index: 212;
  }
  .about-us {
    flex-shrink: 0;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.21px;
    z-index: 209;
  }
  .products-5e {
    flex-shrink: 0;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.21px;
    z-index: 212;
  }
  .flex-row-be {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 257px;
    height: 16px;
    margin: 16px 0 0 0;
    z-index: 213;
  }
  .our-pillars {
    flex-shrink: 0;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.21px;
    z-index: 210;
  }
  .contact-us {
    flex-shrink: 0;
    position: relative;
    height: 16px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.21px;
    z-index: 213;
  }
  .div-5f {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 342px;
    height: 1px;
    margin: 32px 0 0 0;
    background: rgba(255, 255, 255, 0.3);
    z-index: -Infinity;
  }
  .flex-row-60 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 342px;
    height: 18px;
    margin: 30px 0 0 0;
    z-index: 216;
  }
  .all-rights-reserved {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    width: 117px;
    height: 14px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    white-space: nowrap;
    z-index: 216;
  }
  .socials {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 12px;
    position: relative;
    width: 78px;
    z-index: 202;
  }
  .insta {
    flex-shrink: 0;
    position: relative;
    width: 18px;
    height: 18px;
    background: url(./assets/images/158aceb2-5a8f-4432-9e3e-23148913d59e.png)
      no-repeat center;
    background-size: cover;
    z-index: 203;
    overflow: hidden;
    border-radius: 500px;
  }
  .facebook {
    flex-shrink: 0;
    position: relative;
    width: 18px;
    height: 18px;
    background: url(./assets/images/89c63e31-137b-433a-80c8-26f4ec6c3e72.png)
      no-repeat center;
    background-size: cover;
    z-index: 204;
    overflow: hidden;
    border-radius: 500px;
  }
  .linked-in {
    flex-shrink: 0;
    position: relative;
    width: 18px;
    height: 18px;
    background: url(./assets/images/8a550fe2-0434-4126-9294-9073405b3a44.png)
      no-repeat center;
    background-size: cover;
    z-index: 205;
    overflow: hidden;
    border-radius: 500px;
  }
  .logo-crypto {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    width: 141px;
    height: 31.744px;
    top: 26px;
    right: 24px;
    z-index: 217;
  }
  .header-logo-61 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    width: 154px;
    z-index: 218;
    overflow: hidden;
  }
  .logo-62 {
    flex-shrink: 0;
    position: relative;
    width: 140.8px;
    height: 31.744px;
    z-index: 219;
    overflow: hidden;
  }
  .group-63 {
    position: absolute;
    width: 167.117px;
    height: 36.454px;
    top: 0;
    left: 0;
    background: url(./assets/images/86532f29-877a-450c-8d58-f1bb62adca40.png)
      no-repeat center;
    background-size: cover;
    z-index: 220;
  }
  .mob {
    position: absolute;
    width: 100%;
    height: 13891px;
    top: 0;
    left: 0;
    background: url(./assets/images/6abb96ff1bb5da72bae2a1f2916c7c2aa1828cdb.png)
      no-repeat center;
    background-size: cover;
  }
}

@media (max-width:375) {
  .close-btn {
    position: absolute;
    top: 27px;
    right: 18px;
    font-size: 24px;
    color: white;
    cursor: pointer;
}
}
/* Code for tablet */
@media only screen and (min-width: 427px) and (max-width: 1024px) {
  body {
    margin: 0;
  }
  :root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }

  .main-container {
    overflow: hidden;
  }

  .main-container,
  .main-container * {
    box-sizing: border-box;
  }

  input,
  select,
  textarea,
  button {
    outline: 0;
  }

  .main-container {
    position: relative;
    width: 1024px;
    height: 28513px;
    margin: 0 auto;
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: url(./assets/images/tab.png) no-repeat center;
  }

  .popup-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .close-btn {
    position: absolute;
    top: 80.738px;
    right: 80.745px;
    font-size: 24px;
    color: white;
    cursor: pointer;
    z-index: 1000;
  }
  .close-btn img {
    width: 82.708px;
    height: 82.708px;
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 50px;
    margin: 40px 0;
    transition: color 0.3s ease-in-out;
  }

  a:hover {
    color: #4ba629;
  }

  .innovating-for-better-lives {
    position: relative;
    width: 607.311px;
    height: 240px;
    margin: 283px 0 0 208px;
    font-family: DM Sans, var(--default-font-family);
    font-size: 89.79692077636719px;
    font-weight: 700;
    line-height: 120px;
    text-align: center;
    letter-spacing: -2.36px;
    z-index: 87;
  }
  .innovating {
    position: relative;
    color: #4ba629;
    font-family: DM Sans, var(--default-font-family);
    font-size: 89.79692077636719px;
    font-weight: 700;
    line-height: 120px;
    text-align: center;
    letter-spacing: -2.36px;
  }
  .for-better-lives {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 89.79692077636719px;
    font-weight: 700;
    line-height: 120px;
    text-align: center;
    letter-spacing: -2.36px;
  }
  .empowering-productivity {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 731px;
    height: 96px;
    margin: 24px 0 0 146px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 88;
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 5.251px;
    position: relative;
    width: 469.99px;
    margin: 84px 0 0 277px;
    padding: 35.446px 42.01px 35.446px 42.01px;
    background: #4ba629;
    z-index: 97;
    border-radius: 105.026px;
  }
  .lets-start {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 145px;
    height: 21px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 21.005128860473633px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 2.1px;
    z-index: 98;
  }
  .apple-computers {
    position: relative;
    width: 930.58px;
    height: 560.648px;
    margin: 106.94px 0 0 46.703px;
    background: url(./assets/images/tab-apple.png) no-repeat center;
    background-size: cover;
    z-index: 99;
  }
  .download-app {
    position: relative;
    width: 1024px;
    height: 1392px;
    margin: 127.52px 0 0 0;
    background: #4ba629;
    z-index: 100;
    overflow: hidden;
  }
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    gap: 29.407px;
    position: relative;
    width: 796px;
    margin: 88.281px 0 0 114px;
    z-index: 101;
  }
  .shaping-a-smarter-world {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    width: 700px;
    height: 133px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 51.46256637573242px;
    font-weight: 700;
    line-height: 66.166px;
    text-align: center;
    z-index: 102;
  }
  .at-inventcare {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    width: 796px;
    height: 335px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 103;
  }
  .group {
    position: relative;
    width: 781.122px;
    height: 839.093px;
    margin: 92.312px 0 0 120.781px;
    background: url(./assets/images/a1d55cea-9960-4f8a-9751-7a5cc04ecb0b.png)
      no-repeat center;
    background-size: cover;
    z-index: 104;
  }
  .heading-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 30.247px;
    position: relative;
    width: 702px;
    margin: 104.246px 0 0 161px;
    z-index: 51;
  }
  .our-pillars-of-innovation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 561.6px;
    height: 69px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 52.93292236328125px;
    font-weight: 700;
    line-height: 68.057px;
    text-align: left;
    white-space: nowrap;
    z-index: 52;
  }
  .driving-meaningful-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    width: 702px;
    height: 99px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29px;
    font-weight: 400;
    line-height: 49.152px;
    text-align: center;
    z-index: 53;
  }
  .articles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 33.608px;
    position: relative;
    width: 752px;
    margin: 80.507px 0 0 136.008px;
    z-index: 54;
  }
  .blog-card-mobile {
    flex-shrink: 0;
    position: relative;
    width: 752px;
    height: 936px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 55;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div {
    position: absolute;
    width: 802.396px;
    height: 451.61px;
    top: 0;
    left: 0;
    z-index: 56;
    overflow: hidden;
  }
  .eaebefc-image-blog-crypto {
    position: absolute;
    height: 451.61px;
    top: 50%;
    right: 0;
    left: 0;
    background: url(./assets/images/tab-book.png) no-repeat center;
    background-size: cover;
    transform: translate(0, -50%);
    z-index: 57;
  }
  .div-2 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    position: absolute;
    width: 428.505px;
    height: 110.814px;
    top: 397.387px;
    left: 50%;
    padding: 29.407px 46.211px 29.407px 46.211px;
    background: #4ba629;
    transform: translate(-50%, 0);
    z-index: 60;
    border-radius: 3150.769px;
  }
  .productivity {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 52px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 34.02830505371094px;
    font-weight: 600;
    line-height: 52px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.98px;
    z-index: 61;
  }
  .div-3 {
    position: absolute;
    width: 752px;
    height: 484px;
    top: 452px;
    left: -0.01px;
    z-index: 58;
  }
  .simplifying-tasks {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 652px;
    height: 312px;
    top: 97.391px;
    left: calc(50% - 326.01px);
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29px;
    font-weight: 400;
    line-height: 52px;
    text-align: center;
    z-index: 59;
  }
  .articles-4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 33.608px;
    position: relative;
    width: 751.984px;
    height: 936px;
    margin: 60px 0 0 136px;
    z-index: 62;
  }
  .blog-card-mobile-5 {
    flex-shrink: 0;
    position: relative;
    width: 751.984px;
    height: 936px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 63;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div-6 {
    position: absolute;
    width: 802.396px;
    height: 451.61px;
    top: 0;
    left: 0;
    z-index: 64;
    overflow: hidden;
  }
  .eaebefc-image-blog-crypto-7 {
    position: absolute;
    height: 451.61px;
    top: 50%;
    right: 0;
    left: 0;
    background: url(./assets/images/2tab.png) no-repeat center;
    background-size: cover;
    transform: translate(0, -50%);
    z-index: 65;
  }
  .div-8 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    position: absolute;
    width: 323.479px;
    height: 107.814px;
    top: 397.387px;
    left: 50%;
    padding: 29.407px 46.211px 29.407px 46.211px;
    background: #4ba629;
    transform: translate(-50%, 0);
    z-index: 68;
    border-radius: 3150.769px;
  }
  .learning {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 49px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 34px;
    font-weight: 600;
    line-height: 49px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.87px;
    z-index: 69;
  }
  .div-9 {
    position: absolute;
    width: 752px;
    height: 484px;
    top: 452px;
    left: 0;
    z-index: 66;
  }
  .creating-platforms {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 651.159px;
    height: 312px;
    top: 95.135px;
    left: 50.414px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29px;
    font-weight: 400;
    line-height: 52px;
    text-align: center;
    z-index: 67;
  }
  .articles-a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 33.608px;
    position: relative;
    width: 751.984px;
    margin: 60.512px 0 0 136.008px;
    z-index: 70;
  }
  .blog-card-mobile-b {
    flex-shrink: 0;
    position: relative;
    width: 751.984px;
    height: 936px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 71;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div-c {
    position: absolute;
    width: 802.396px;
    height: 451.61px;
    top: 0;
    left: 0;
    z-index: 72;
    overflow: hidden;
  }
  .eaebefc-image-blog-crypto-d {
    position: absolute;
    height: 451.61px;
    top: 50%;
    right: 0;
    left: 0;
    background: url(./assets/images/3tab.png) no-repeat center;
    background-size: cover;
    transform: translate(0, -50%);
    z-index: 73;
  }
  .div-e {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    position: absolute;
    width: 350.786px;
    height: 107.814px;
    top: 396.875px;
    left: 50%;
    padding: 29.407px 46.211px 29.407px 46.211px;
    background: #4ba629;
    transform: translate(-49.7%, 0);
    z-index: 76;
    border-radius: 3150.769px;
  }
  .creativity {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 49px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 34px;
    font-weight: 600;
    line-height: 49px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.87px;
    z-index: 77;
  }
  .div-f {
    position: absolute;
    width: 752px;
    height: 485px;
    top: 451.488px;
    left: -0.01px;
    z-index: 74;
  }
  .encouraging-self-expression {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 651.159px;
    height: 312px;
    top: 94.328px;
    left: 50.414px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29px;
    font-weight: 400;
    line-height: 52px;
    text-align: center;
    z-index: 75;
  }
  .articles-10 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 33.608px;
    position: relative;
    width: 751.984px;
    margin: 60.488px 0 0 136.008px;
    z-index: 78;
  }
  .blog-card-mobile-11 {
    flex-shrink: 0;
    position: relative;
    width: 751.984px;
    height: 936px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 79;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div-12 {
    position: absolute;
    width: 802.396px;
    height: 451.61px;
    top: 0;
    left: 0;
    z-index: 80;
    overflow: hidden;
  }
  .eaebefc-image-blog-crypto-13 {
    position: absolute;
    height: 451.61px;
    top: 50%;
    right: 0;
    left: 0;
    background: url(./assets/images/4tab.png) no-repeat center;
    background-size: cover;
    transform: translate(0, -50%);
    z-index: 81;
  }
  .div-14 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    position: absolute;
    width: 273.067px;
    height: 107.814px;
    top: 396.617px;
    left: 50%;
    padding: 29.407px 46.211px 29.407px 46.211px;
    background: #4ba629;
    transform: translate(-50%, 0);
    z-index: 84;
    border-radius: 3150.769px;
  }
  .safety {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 49px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 34px;
    font-weight: 600;
    line-height: 49px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.87px;
    z-index: 85;
  }
  .div-15 {
    position: absolute;
    width: 752px;
    height: 485px;
    top: 451.23px;
    left: -0.01px;
    z-index: 82;
  }
  .prioritizing-well-being {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 651.159px;
    height: 312px;
    top: 94.729px;
    left: 50.414px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29px;
    font-weight: 400;
    line-height: 52px;
    text-align: center;
    z-index: 83;
  }
  .download-app-16 {
    position: relative;
    width: 1024px;
    height: 2662px;
    margin: 124px 0 0 0;
    background: #4ba629;
    z-index: 105;
    overflow: hidden;
  }
  .create-products {
    position: relative;
    width: 890px;
    height: 160px;
    margin: 105.025px 0 0 67px;
    font-family: DM Sans, var(--default-font-family);
    font-size: 53.52000045776367px;
    font-weight: 700;
    line-height: 80px;
    text-align: center;
    z-index: 106;
  }
  .at {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 53.52000045776367px;
    font-weight: 500;
    line-height: 80px;
    text-align: center;
  }
  .inventcare {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 53.52000045776367px;
    font-weight: 400;
    line-height: 80px;
    text-align: center;
  }
  .committed-products {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 53.52000045776367px;
    font-weight: 700;
    line-height: 80px;
    text-align: center;
  }
  .at-17 {
    position: relative;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 53.52000045776367px;
    font-weight: 500;
    line-height: 80px;
    text-align: center;
  }
  .frame {
    position: relative;
    width: 902.512px;
    height: 1485.035px;
    margin: 99.975px 0 0 67px;
    z-index: 109;
    overflow: visible auto;
  }
  .flex-row-cdf {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 902.514px;
    height: 399.678px;
    margin: 0 0 0 0;
    z-index: 116;
  }
  .group-18 {
    flex-shrink: 0;
    position: relative;
    width: 433.756px;
    height: 399.678px;
    z-index: 110;
    border-radius: 66.901px;
  }
  .div-19 {
    position: relative;
    width: 236.728px;
    height: 236.728px;
    margin: 0 0 0 98.699px;
    background: #4ba629;
    z-index: 111;
    overflow: hidden;
    border-radius: 66.901px;
  }
  .icon-spec-crypto {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 144.095px;
    margin: 36.02px 0 0 46.313px;
    z-index: 112;
  }
  .icon-spec-crypto-1a {
    flex-shrink: 0;
    position: relative;
    width: 144.095px;
    height: 169.826px;
    background: url(./assets/images/b6458f59-f7dd-4726-aeea-2470f9a9b6d7.png)
      no-repeat center;
    background-size: cover;
    z-index: 113;
    overflow: hidden;
  }
  .boost-productivity {
    display: block;
    position: relative;
    height: 42px;
    margin: 15.622px 0 0 73.52px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 30.87753677368164px;
    font-weight: 900;
    line-height: 41.175px;
    text-align: left;
    white-space: nowrap;
    z-index: 114;
  }
  .empowering-tools {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 433.756px;
    height: 83px;
    margin: 22.328px 0 0 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 25.73128318786621px;
    font-weight: 400;
    line-height: 41.175px;
    text-align: center;
    z-index: 115;
  }
  .group-1b {
    flex-shrink: 0;
    position: relative;
    width: 433.756px;
    height: 399.678px;
    z-index: 116;
    border-radius: 66.901px;
  }
  .div-1c {
    position: relative;
    width: 236.728px;
    height: 236.728px;
    margin: 0 0 0 98.699px;
    background: #4ba629;
    z-index: 117;
    overflow: hidden;
    border-radius: 66.901px;
  }
  .icon-spec-crypto-1d {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 144.095px;
    margin: 36.02px 0 0 46.313px;
    z-index: 118;
  }
  .icon-spec-crypto-1e {
    flex-shrink: 0;
    position: relative;
    width: 144.095px;
    height: 169.826px;
    background: url(./assets/images/9c278c93-8342-44d6-9268-3fd0c0029d91.png)
      no-repeat center;
    background-size: cover;
    z-index: 119;
    overflow: hidden;
  }
  .foster-creativity {
    display: block;
    position: relative;
    height: 42px;
    margin: 15.622px 0 0 88.223px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 30.87753677368164px;
    font-weight: 900;
    line-height: 41.175px;
    text-align: left;
    white-space: nowrap;
    z-index: 120;
  }
  .unlocking-potential {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 433.756px;
    height: 83px;
    margin: 22.328px 0 0 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 25.73128318786621px;
    font-weight: 400;
    line-height: 41.175px;
    text-align: center;
    z-index: 121;
  }
  .flex-row-da {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 902.514px;
    height: 399.678px;
    margin: 143.001px 0 0 0;
    z-index: 128;
  }
  .group-1f {
    flex-shrink: 0;
    position: relative;
    width: 433.756px;
    height: 399.678px;
    z-index: 122;
    border-radius: 66.901px;
  }
  .div-20 {
    position: relative;
    width: 236.728px;
    height: 236.728px;
    margin: 0 0 0 98.699px;
    background: #4ba629;
    z-index: 123;
    overflow: hidden;
    border-radius: 66.901px;
  }
  .icon-spec-crypto-21 {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 144.095px;
    margin: 36.02px 0 0 46.313px;
    z-index: 124;
  }
  .icon-spec-crypto-22 {
    flex-shrink: 0;
    position: relative;
    width: 144.095px;
    height: 169.826px;
    background: url(./assets/images/d13afbd4-cc81-4a17-b77b-705fe98439a0.png)
      no-repeat center;
    background-size: cover;
    z-index: 125;
    overflow: hidden;
  }
  .track-progress {
    display: block;
    position: relative;
    height: 42px;
    margin: 15.624px 0 0 102.926px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 30.87753677368164px;
    font-weight: 900;
    line-height: 41.175px;
    text-align: left;
    white-space: nowrap;
    z-index: 126;
  }
  .measure-achievements {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 433.756px;
    height: 83px;
    margin: 22.328px 0 0 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 25.73128318786621px;
    font-weight: 400;
    line-height: 41.175px;
    text-align: center;
    z-index: 127;
  }
  .group-23 {
    flex-shrink: 0;
    position: relative;
    width: 433.756px;
    height: 399.678px;
    z-index: 128;
    border-radius: 66.901px;
  }
  .div-24 {
    position: relative;
    width: 236.728px;
    height: 236.728px;
    margin: 0 0 0 98.699px;
    background: #4ba629;
    z-index: 129;
    overflow: hidden;
    border-radius: 66.901px;
  }
  .icon-spec-crypto-25 {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 144.095px;
    margin: 36.02px 0 0 46.313px;
    z-index: 130;
  }
  .icon-spec-crypto-26 {
    flex-shrink: 0;
    position: relative;
    width: 144.095px;
    height: 169.826px;
    background: url(./assets/images/868c3119-fa26-412a-b158-4f887453c932.png)
      no-repeat center;
    background-size: cover;
    z-index: 131;
    overflow: hidden;
  }
  .ensure-safety {
    display: block;
    position: relative;
    height: 42px;
    margin: 15.624px 0 0 110.277px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 30.87753677368164px;
    font-weight: 900;
    line-height: 41.175px;
    text-align: left;
    white-space: nowrap;
    z-index: 132;
  }
  .secure-loved-ones {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 433.756px;
    height: 83px;
    margin: 22.328px 0 0 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 25.73128318786621px;
    font-weight: 400;
    line-height: 41.175px;
    text-align: center;
    z-index: 133;
  }
  .group-27 {
    position: relative;
    width: 433.756px;
    height: 399.678px;
    margin: 142.998px 0 0 234.379px;
    z-index: 134;
    overflow: visible auto;
    border-radius: 66.901px;
  }
  .div-28 {
    position: relative;
    width: 236.728px;
    height: 236.728px;
    margin: 0 0 0 98.699px;
    background: #4ba629;
    z-index: 135;
    overflow: hidden;
    border-radius: 66.901px;
  }
  .icon-spec-crypto-29 {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 144.095px;
    margin: 36.02px 0 0 46.313px;
    z-index: 136;
  }
  .icon-spec-crypto-2a {
    flex-shrink: 0;
    position: relative;
    width: 144.095px;
    height: 169.826px;
    background: url(./assets/images/5ba6c52b-f6f4-4579-bde2-c75e689958c8.png)
      no-repeat center;
    background-size: cover;
    z-index: 137;
    overflow: hidden;
  }
  .quality-digital-life {
    display: block;
    position: relative;
    height: 42px;
    margin: 15.624px 0 0 56.977px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 30.87753677368164px;
    font-weight: 900;
    line-height: 41.175px;
    text-align: left;
    white-space: nowrap;
    z-index: 138;
  }
  .balanced-technology-use {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 433.756px;
    height: 83px;
    margin: 22.328px 0 0 0;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 25.73128318786621px;
    font-weight: 400;
    line-height: 41.175px;
    text-align: center;
    z-index: 139;
  }
  .group-2b {
    position: relative;
    width: 781.122px;
    height: 839.093px;
    margin: 123.965px 0 0 120.781px;
    z-index: 107;
  }
  .group-2c {
    position: absolute;
    width: 781.122px;
    height: 839.093px;
    top: 50%;
    left: 50%;
    background: url(./assets/images/f5ba67af-41dd-472c-aa6e-492bf4c95982.png)
      no-repeat center;
    background-size: cover;
    transform: translate(-50%, -50%);
    z-index: 108;
  }
  .rectangle {
    position: relative;
    width: 719px;
    height: 90.381px;
    margin: 80px 0 0 153px;
    background: url(./assets/images/757bd17b-8001-47e0-97af-8c5d2999f2f3.png)
      no-repeat center;
    background-size: cover;
    z-index: 177;
  }
  .products {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 431.34px;
    height: 65px;
    top: calc(50% - 33.42px);
    left: calc(50% - 215.72px);
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 37.64119338989258px;
    font-weight: 900;
    line-height: 64.719px;
    text-align: center;
    white-space: nowrap;
    z-index: 178;
  }
  .champsverse {
    display: block;
    position: relative;
    height: 95px;
    margin: 60.619px 0 0 344px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 53.52000045776367px;
    font-weight: 700;
    line-height: 94.523px;
    text-align: left;
    white-space: nowrap;
    z-index: 141;
  }
  .transforming-learning {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 692px;
    height: 144px;
    margin: 16px 0 0 166px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 142;
  }
  .div-2d {
    position: relative;
    width: 761.383px;
    height: 627.396px;
    margin: 60px 0 0 130.027px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 143;
    overflow: hidden;
    border-radius: 85.071px;
  }
  .div-2e {
    position: relative;
    width: 144.62px;
    height: 144.62px;
    margin: 102.086px 0 0 307.973px;
    background: #4ba629;
    z-index: 144;
    overflow: hidden;
    border-radius: 42.535px;
  }
  .student-center {
    position: relative;
    width: 102.085px;
    height: 102.085px;
    margin: 21.266px 0 0 21.27px;
    background: url(./assets/images/a51f5ff074d3b6f466bd34260bf31ef39f514aa2.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 145;
  }
  .learn-grow {
    display: block;
    position: relative;
    height: 55px;
    margin: 43.536px 0 0 233.973px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 37.80923080444336px;
    font-weight: 700;
    line-height: 54.597px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.89px;
    z-index: 146;
  }
  .safe-fun-space {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 559px;
    height: 144px;
    margin: 24.758px 0 0 100.973px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 147;
  }
  .div-2f {
    position: relative;
    width: 761.383px;
    height: 627.396px;
    margin: 34.029px 0 0 130.027px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 148;
    overflow: hidden;
    border-radius: 85.071px;
  }
  .div-30 {
    position: relative;
    width: 144.62px;
    height: 144.62px;
    margin: 102.086px 0 0 307.973px;
    background: #4ba629;
    z-index: 149;
    overflow: hidden;
    border-radius: 42.535px;
  }
  .safety-care {
    position: relative;
    width: 102.085px;
    height: 102.085px;
    margin: 23.395px 0 0 19.141px;
    background: url(./assets/images/a3be43486c5a3ce16a972333f888f116b287c2aa.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 150;
  }
  .safety-well-being {
    display: block;
    position: relative;
    height: 55px;
    margin: 43.536px 0 0 164.973px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 37.80923080444336px;
    font-weight: 700;
    line-height: 54.597px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.89px;
    z-index: 151;
  }
  .safe-digital-space {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 512px;
    height: 144px;
    margin: 25.332px 0 0 124.973px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 152;
  }
  .ios {
    position: relative;
    width: 761.383px;
    height: 1322.85px;
    margin: 34.029px 0 0 130.027px;
    font-size: 0px;
    background: #4ba629;
    z-index: 153;
    overflow: hidden;
    border-radius: 85.071px;
  }
  .ios-android-app {
    display: block;
    position: relative;
    height: 56px;
    margin: 93.578px 0 0 171.973px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 42.53538513183594px;
    font-weight: 700;
    line-height: 55.296px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 2.13px;
    z-index: 154;
  }
  .accessible-platforms {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 542px;
    height: 144px;
    margin: 31.57px 0 0 109.973px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 155;
  }
  .iphones {
    position: relative;
    width: 607.091px;
    height: 1239.906px;
    margin: 47.445px 0 0 76.973px;
    z-index: 156;
  }
  .body {
    position: absolute;
    width: 98.46%;
    height: 100.05%;
    top: 0;
    left: 0.85%;
    background: url(./assets/images/6d270fba-6cdc-4e33-b826-1f5b2181590a.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 157;
  }
  .screen-replace-here {
    position: absolute;
    width: 90.56%;
    height: 96.04%;
    top: 1.94%;
    left: 4.8%;
    background: url(./assets/images/bb8497e2a1b3fe14311c045c3332d7cb14c4e149.png)
      no-repeat center;
    background-size: cover;
    z-index: 163;
    border-radius: 70.605px;
  }
  .dinamy-island {
    position: absolute;
    width: 26.52%;
    height: 3.74%;
    top: 3.04%;
    left: 36.67%;
    background: #131316;
    z-index: 164;
    border-radius: 69.341px;
  }
  .buttons {
    position: absolute;
    width: 100.15%;
    height: 22.56%;
    top: 17.57%;
    left: 0;
    z-index: 158;
    border-radius: 2.013px;
  }
  .flex-column-f {
    position: absolute;
    width: 0.85%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 161;
  }
  .silence-button {
    position: relative;
    width: 5.138px;
    height: 44.61px;
    margin: 0 0 0 0;
    background: #131316;
    z-index: 161;
    border-radius: 2.013px;
  }
  .group-12 {
    position: relative;
    width: 3.425px;
    height: 87.505px;
    margin: 37.745px 0 0 0;
    background: #131316;
    z-index: 159;
    border-radius: 2.013px;
  }
  .box-12 {
    position: relative;
    width: 3.425px;
    height: 87.505px;
    margin: 22.307px 0 0 0;
    background: #131316;
    z-index: 160;
    border-radius: 2.013px;
  }
  .power-button {
    display: flex;
    align-items: flex-start;
    position: absolute;
    width: 0.85%;
    height: 49.08%;
    top: 50.31%;
    left: 99.16%;
    background: #131316;
    z-index: -Infinity;
    border-radius: 2.013px;
  }
  .div-31 {
    position: relative;
    width: 761.383px;
    height: 627.396px;
    margin: 34.028px 0 0 130.027px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 165;
    overflow: hidden;
    border-radius: 85.071px;
  }
  .div-32 {
    position: relative;
    width: 144.62px;
    height: 144.62px;
    margin: 102.082px 0 0 307.973px;
    background: #4ba629;
    z-index: 166;
    overflow: hidden;
    border-radius: 42.535px;
  }
  .bright-mind {
    position: relative;
    width: 102.085px;
    height: 102.085px;
    margin: 21.27px 0 0 21.27px;
    background: url(./assets/images/4478c8465ba442557fef64971893da2bcfea5a95.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 167;
  }
  .creativity-33 {
    display: block;
    position: relative;
    height: 55px;
    margin: 43.536px 0 0 270.973px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 37.80923080444336px;
    font-weight: 700;
    line-height: 54.597px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.89px;
    z-index: 168;
  }
  .young-artists {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 536px;
    height: 144px;
    margin: 25.031px 0 0 112.973px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 169;
  }
  .div-34 {
    position: relative;
    width: 761.383px;
    height: 627.396px;
    margin: 34.029px 0 0 130.027px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 170;
    overflow: hidden;
    border-radius: 85.071px;
  }
  .div-35 {
    position: relative;
    width: 144.62px;
    height: 144.62px;
    margin: 102.086px 0 0 307.973px;
    background: #4ba629;
    z-index: 171;
    overflow: hidden;
    border-radius: 42.535px;
  }
  .eaeefba-icon-section-about-crypto-template {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    width: 123.353px;
    height: 99.959px;
    top: 50%;
    left: 50%;
    padding: 0;
    transform: translate(-50%, -51.06%);
    z-index: 172;
  }
  .guardian {
    position: absolute;
    width: 102.085px;
    height: 102.085px;
    top: 50%;
    left: 50%;
    background: url(./assets/images/46af49cb2aa960bbd5b420b803958dd7d8f36d3f.png)
      no-repeat center;
    background-size: contain;
    transform: translate(-50%, -50%);
    opacity: 0.8;
    z-index: 173;
  }
  .parental-tools {
    display: block;
    position: relative;
    height: 55px;
    margin: 43.532px 0 0 216.973px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 37.80923080444336px;
    font-weight: 700;
    line-height: 54.597px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.89px;
    z-index: 174;
  }
  .easy-to-use-features {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 593.369px;
    height: 144px;
    margin: 25.184px 0 0 83.973px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 175;
  }
  .champsverse-iot {
    display: block;
    position: relative;
    height: 95px;
    margin: 101.447px 0 0 279px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 53.52000045776367px;
    font-weight: 700;
    line-height: 94.523px;
    text-align: left;
    white-space: nowrap;
    z-index: 2;
  }
  .revolutionizing-play {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 742px;
    height: 96px;
    margin: 17px 0 0 142px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 3;
  }
  .div-36 {
    position: relative;
    width: 751.984px;
    height: 619.651px;
    margin: 83px 0 0 136.008px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 4;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div-37 {
    position: relative;
    width: 142.835px;
    height: 142.835px;
    margin: 100.824px 0 0 304.992px;
    background: #4ba629;
    z-index: 5;
    overflow: hidden;
    border-radius: 42.01px;
  }
  .fast-forward {
    position: relative;
    width: 100.825px;
    height: 100.825px;
    margin: 21.004px 0 0 21.004px;
    background: url(./assets/images/1dda445150e7c58aa5eb264bafa79cfcc9297ce8.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 6;
  }
  .next-gen-toy-design {
    display: block;
    position: relative;
    height: 55px;
    margin: 42.013px 0 0 156.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 37.80923080444336px;
    font-weight: 700;
    line-height: 54.597px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.89px;
    z-index: 7;
  }
  .iot-technology {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 513px;
    height: 144px;
    margin: 24.328px 0 0 119.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 8;
  }
  .div-38 {
    position: relative;
    width: 751.984px;
    height: 619.651px;
    margin: 33.607px 0 0 136.008px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 9;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div-39 {
    position: relative;
    width: 142.835px;
    height: 142.835px;
    margin: 100.824px 0 0 304.992px;
    background: #4ba629;
    z-index: 10;
    overflow: hidden;
    border-radius: 42.01px;
  }
  .ping-pong {
    position: relative;
    width: 100.825px;
    height: 100.825px;
    margin: 21.004px 0 0 21.004px;
    background: url(./assets/images/d0ee65febd96cfcdd8ea484a7115e46817aab03e.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 11;
  }
  .engaging-fun-games {
    display: block;
    position: relative;
    height: 55px;
    margin: 42.013px 0 0 110.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 37.80923080444336px;
    font-weight: 700;
    line-height: 54.597px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.89px;
    z-index: 12;
  }
  .interactive-games {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 533px;
    height: 144px;
    margin: 24.07px 0 0 109.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 13;
  }
  .ios-3a {
    position: relative;
    width: 751.984px;
    height: 1306.519px;
    margin: 33.61px 0 0 136.008px;
    background: url(./assets/images/f7f77aa57179dfe44dd3eecdf472a9cfd8aac798.png)
      no-repeat center;
    background-size: cover;
    z-index: 24;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div-3b {
    position: relative;
    width: 751.984px;
    height: 619.65px;
    margin: 33.61px 0 0 136.008px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 14;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div-3c {
    position: relative;
    width: 142.835px;
    height: 142.835px;
    margin: 100.824px 0 0 304.992px;
    background: #4ba629;
    z-index: 15;
    overflow: hidden;
    border-radius: 42.01px;
  }
  .internet {
    position: relative;
    width: 100.825px;
    height: 100.825px;
    margin: 21.004px 0 0 21.004px;
    background: url(./assets/images/23e9748bf5692e4b86582e0306cecd0bf6605b4a.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 16;
  }
  .reduced-online-time {
    display: block;
    position: relative;
    height: 55px;
    margin: 42.009px 0 0 161.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 37.80923080444336px;
    font-weight: 700;
    line-height: 54.597px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.89px;
    z-index: 17;
  }
  .offline-entertainment-options {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 461px;
    height: 144px;
    margin: 24.684px 0 0 145.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 18;
  }
  .div-3d {
    position: relative;
    width: 751.984px;
    height: 619.65px;
    margin: 33.607px 0 0 136.008px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 19;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div-3e {
    position: relative;
    width: 142.835px;
    height: 142.835px;
    margin: 100.824px 0 0 304.992px;
    background: #4ba629;
    z-index: 20;
    overflow: hidden;
    border-radius: 42.01px;
  }
  .development-skill {
    position: relative;
    width: 100.825px;
    height: 100.825px;
    margin: 21.004px 0 0 21.004px;
    background: url(./assets/images/55ae2f6c1bb34ea7f2b458896944556d6b9d8e64.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 21;
  }
  .enhanced-development {
    display: block;
    position: relative;
    height: 55px;
    margin: 42.009px 0 0 124.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 37.80923080444336px;
    font-weight: 700;
    line-height: 54.597px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.89px;
    z-index: 22;
  }
  .boosting-mental-physical {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 486px;
    height: 144px;
    margin: 24.426px 0 0 132.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 23;
  }
  .inventcare-safety {
    display: block;
    position: relative;
    height: 95px;
    margin: 148.443px 0 0 187.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 53.52000045776367px;
    font-weight: 700;
    line-height: 94.523px;
    text-align: left;
    white-space: nowrap;
    z-index: 26;
  }
  .innovative-safety-solutions {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 622px;
    height: 96px;
    margin: 16px 0 0 200.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 27;
  }
  .div-3f {
    position: relative;
    width: 751.984px;
    height: 619.65px;
    margin: 84px 0 0 136px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 28;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div-40 {
    position: relative;
    width: 142.835px;
    height: 142.835px;
    margin: 100.824px 0 0 304.992px;
    background: #4ba629;
    z-index: 29;
    overflow: hidden;
    border-radius: 42.01px;
  }
  .protect {
    position: relative;
    width: 100.825px;
    height: 100.825px;
    margin: 21.008px 0 0 21.004px;
    background: url(./assets/images/5a25c34f43aa7fd3b353d7bb98f8df7e4a44b691.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 30;
  }
  .ensuring-safety {
    display: block;
    position: relative;
    height: 55px;
    margin: 42.009px 0 0 203.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 37.80923080444336px;
    font-weight: 700;
    line-height: 54.597px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.89px;
    z-index: 31;
  }
  .well-being-security {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 428px;
    height: 144px;
    margin: 24.332px 0 0 161.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 32;
  }
  .div-41 {
    position: relative;
    width: 751.984px;
    height: 619.65px;
    margin: 33.607px 0 0 136px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 33;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div-42 {
    position: relative;
    width: 142.835px;
    height: 142.835px;
    margin: 100.824px 0 0 304.992px;
    background: #4ba629;
    z-index: 34;
    overflow: hidden;
    border-radius: 42.01px;
  }
  .eaefefbb-icon-section-about-crypto-template {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 100.825px;
    margin: 19.953px 0 0 19.953px;
    padding: 0 0px 0 0px;
    z-index: 35;
  }
  .private-wall-mount-camera {
    flex-shrink: 0;
    position: relative;
    width: 100.825px;
    height: 100.825px;
    background: url(./assets/images/b70b6909ba281183d503239b729be6db6f740108.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 36;
  }
  .advanced-technology {
    display: block;
    position: relative;
    height: 55px;
    margin: 42.013px 0 0 132.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 37.80923080444336px;
    font-weight: 700;
    line-height: 54.597px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.89px;
    z-index: 37;
  }
  .safety-convenience {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 460px;
    height: 144px;
    margin: 24.07px 0 0 145.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 38;
  }
  .ios-43 {
    position: relative;
    width: 751.984px;
    height: 1306.52px;
    margin: 33.607px 0 0 136px;
    background: url(./assets/images/ae59d0c9-da91-4c0a-9fd7-1ae42b5968c5.png)
      no-repeat center;
    background-size: cover;
    z-index: 49;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div-44 {
    position: relative;
    width: 751.984px;
    height: 619.65px;
    margin: 33.609px 0 0 136px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 39;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div-45 {
    position: relative;
    width: 142.835px;
    height: 142.835px;
    margin: 100.824px 0 0 304.992px;
    background: #4ba629;
    z-index: 40;
    overflow: hidden;
    border-radius: 42.01px;
  }
  .processor {
    position: relative;
    width: 92.423px;
    height: 92.423px;
    margin: 25.207px 0 0 25.207px;
    background: url(./assets/images/d206df11311ffcc16675c8939a8aa2cb8ddcdc06.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 41;
  }
  .surveillance {
    display: block;
    position: relative;
    height: 55px;
    margin: 42.013px 0 0 235.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 37.80923080444336px;
    font-weight: 700;
    line-height: 54.597px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.89px;
    z-index: 42;
  }
  .awareness-protection {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 481px;
    height: 144px;
    margin: 23.684px 0 0 135.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 43;
  }
  .div-46 {
    position: relative;
    width: 751.984px;
    height: 619.65px;
    margin: 33.611px 0 0 136.008px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 44;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .div-47 {
    position: relative;
    width: 142.835px;
    height: 142.835px;
    margin: 100.824px auto 0;
    background: #4ba629;
    z-index: 45;
    overflow: hidden;
    border-radius: 42.01px;
  }
  .male-user {
    position: relative;
    width: 100.825px;
    height: 100.825px;
    margin: 21.566px 0 0 21.004px;
    background: url(./assets/images/a77954abbdc5359c3b398aaed29b9aa4ca9d3b49.png)
      no-repeat center;
    background-size: contain;
    opacity: 0.8;
    z-index: 46;
  }
  .user-friendly-access {
    display: block;
    position: relative;
    height: 55px;
    margin: 42.009px 0 0 146.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 37.80923080444336px;
    font-weight: 700;
    line-height: 54.597px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 1.89px;
    z-index: 47;
  }
  .intuitive-user-friendly {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 586.043px;
    height: 144px;
    margin: 42.52px 0 0 82.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 48;
  }
  .download-champsverse {
    display: block;
    position: relative;
    height: 95px;
    margin: 132.443px 0 0 217.5px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 53.52000045776367px;
    font-weight: 700;
    line-height: 94.523px;
    text-align: left;
    white-space: nowrap;
    z-index: 181;
  }
  .android-ios-functionality {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 570px;
    height: 144px;
    margin: 24px 0 0 227px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29.407180786132812px;
    font-weight: 400;
    line-height: 47.787px;
    text-align: center;
    z-index: 182;
  }
  .ios-48 {
    position: relative;
    width: 751.984px;
    height: 1218.297px;
    margin: 55px 0 0 136.008px;
    font-size: 0px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 183;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .download-for-ios {
    display: block;
    position: relative;
    height: 95px;
    margin: 58.215px 0 0 168.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 53.52000045776367px;
    font-weight: 700;
    line-height: 94.523px;
    text-align: left;
    white-space: nowrap;
    z-index: 184;
  }
  .ios-49 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 16.804px;
    position: relative;
    width: 405.399px;
    height: 147.036px;
    margin: 56.836px 0 0 172.992px;
    padding: 56.714px 67.216px 56.714px 67.216px;
    background: #4ba629;
    z-index: 194;
    border-radius: 168.041px;
  }
  .eaeeeefbdc-icon-app-crypto-template {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 48.312px;
    padding: 0px 0 0px 0;
    z-index: 195;
  }
  .eaeeeefbdc-icon-app-crypto-template-4a {
    flex-shrink: 0;
    position: relative;
    width: 48.312px;
    height: 58.814px;
    background: url(./assets/images/726b1d08-c850-46e1-bb62-01279db74916.png)
      no-repeat center;
    background-size: cover;
    z-index: 196;
    overflow: hidden;
  }
  .app-store {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 200px;
    height: 34px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 33.60820770263672px;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 3.36px;
    z-index: 197;
  }
  .iphones-4b {
    position: relative;
    width: 597.799px;
    height: 1220.398px;
    margin: 84.019px 0 0 77.719px;
    z-index: 185;
  }
  .body-4c {
    position: absolute;
    width: 98.37%;
    height: 100.04%;
    top: 0;
    left: 0.87%;
    background: url(./assets/images/d75534e4-23af-4115-9278-d53fed1017c0.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 186;
  }
  .screen-replace-here-4d {
    position: absolute;
    width: 90.52%;
    height: 96.08%;
    top: 1.93%;
    left: 4.58%;
    background: url(./assets/images/bb8497e2a1b3fe14311c045c3332d7cb14c4e149.png)
      no-repeat center;
    background-size: cover;
    z-index: 192;
    border-radius: 69.503px;
  }
  .dinamy-island-4e {
    position: absolute;
    width: 26.61%;
    height: 3.85%;
    top: 3%;
    left: 36.86%;
    background: #1b1b1f;
    z-index: 193;
    border-radius: 68.259px;
  }
  .buttons-4f {
    position: absolute;
    width: 100.11%;
    height: 22.47%;
    top: 17.65%;
    left: 0;
    z-index: 187;
    border-radius: 1.982px;
  }
  .silence-button-50 {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 5.216px;
    height: 43.088px;
    margin: 0 0 0 0;
    background: #1b1b1f;
    z-index: -Infinity;
    border-radius: 1.982px;
  }
  .flex-column-e {
    position: absolute;
    width: 0.65%;
    height: 70.95%;
    top: 29.05%;
    left: 0.22%;
    z-index: 189;
  }
  .plus-button {
    position: relative;
    width: 3.912px;
    height: 86.177px;
    margin: 0 0 0 0;
    background: #1b1b1f;
    z-index: 188;
    border-radius: 1.982px;
  }
  .plus-button-51 {
    position: relative;
    width: 3.912px;
    height: 86.177px;
    margin: 22.198px 0 0 0;
    background: #1b1b1f;
    z-index: 189;
    border-radius: 1.982px;
  }
  .power-button-52 {
    display: flex;
    align-items: flex-start;
    position: absolute;
    width: 0.87%;
    height: 49.05%;
    top: 50.48%;
    left: 99.13%;
    background: #1b1b1f;
    z-index: -Infinity;
    border-radius: 1.982px;
  }
  .android {
    position: relative;
    width: 751.984px;
    height: 1218.297px;
    margin: 42.008px 0 0 136.008px;
    font-size: 0px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 198;
    overflow: hidden;
    border-radius: 84.021px;
  }
  .download-for-android {
    display: block;
    position: relative;
    height: 95px;
    margin: 58.215px 0 0 113.992px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 53.52000045776367px;
    font-weight: 700;
    line-height: 94.523px;
    text-align: left;
    white-space: nowrap;
    z-index: 199;
  }
  .button-53 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 16.804px;
    position: relative;
    width: 418.549px;
    height: 147.036px;
    margin: 56.836px 0 0 166.992px;
    padding: 56.714px 67.216px 56.714px 67.216px;
    background: #4ba629;
    z-index: 200;
    border-radius: 168.041px;
  }
  .eaeedefbe-icon-app-crypto-template {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 48.312px;
    padding: 0 0px 0 0px;
    z-index: 201;
  }
  .icon-app-crypto {
    flex-shrink: 0;
    position: relative;
    width: 48.312px;
    height: 52.513px;
    background: url(./assets/images/b078c774-2995-47af-8a22-9e6556a6f264.png)
      no-repeat center;
    background-size: cover;
    z-index: 202;
    overflow: hidden;
  }
  .play-store {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 219px;
    height: 34px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 33.60820770263672px;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 3.36px;
    z-index: 203;
  }
  .samsung-galaxys {
    position: relative;
    width: 592.578px;
    height: 1220.398px;
    margin: 84.023px 0 0 79.82px;
    background: url(./assets/images/031d09db-9871-4733-9f0e-1c84f29b4298.png)
      no-repeat center;
    background-size: cover;
    z-index: 204;
    overflow: hidden;
  }
  .frame-54 {
    position: relative;
    width: 1024px;
    height: 1367.959px;
    margin: 140.398px 0 0 0;
    background: #4ba629;
    z-index: 225;
    overflow: hidden;
  }
  .div-55 {
    position: relative;
    width: 178.544px;
    height: 178.544px;
    margin: 130.051px 0 0 422.727px;
    background: #ffffff;
    z-index: 226;
    overflow: hidden;
    border-radius: 52.513px;
  }
  .icon-newsletter-crypto {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 110.277px;
    margin: 42.012px 0 0 34.133px;
    z-index: 227;
  }
  .icon-newsletter-crypto-56 {
    flex-shrink: 0;
    position: relative;
    width: 110.277px;
    height: 97.149px;
    background: url(./assets/images/51c075cd-2526-4019-8506-725ee39cd034.png)
      no-repeat center;
    background-size: cover;
    z-index: 228;
    overflow: hidden;
  }
  .share-email {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 640px;
    height: 284px;
    margin: 60.406px 0 0 192px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 53.52000045776367px;
    font-weight: 700;
    line-height: 94.523px;
    text-align: center;
    z-index: 229;
  }
  .input-email {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    width: 939.979px;
    height: 210.051px;
    margin: 61.176px 0 0 42.012px;
    padding: 21.005px 21.005px 21.005px 81px;
    background: #ffffff;
    border: 5.251px solid #ffffff;
    z-index: 232;
    border-radius: 262.564px;
  }
  .enter-email-address {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 69px;
    color: #000625;
    font-family: DM Sans, var(--default-font-family);
    font-size: 32px;
    font-weight: 400;
    line-height: 69px;
    text-align: left;
    white-space: nowrap;
    z-index: 233;
  }
  .button-57 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10.503px;
    position: relative;
    width: 929.477px;
    margin: 52.511px 0 0 42.012px;
    padding: 70.892px 84.021px 70.892px 84.021px;
    background: #050505;
    z-index: 230;
    border-radius: 210.051px;
  }
  .button-share {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 117px;
    height: 43px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 43px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 4.2px;
    z-index: 231;
  }
  .footer-mobile {
    position: relative;
    width: 1024px;
    height: 790px;
    margin: 0.04px 0 0 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 205;
    overflow: hidden;
  }
  .group-58 {
    position: absolute;
    width: 897.969px;
    height: 630.134px;
    top: 76.164px;
    left: 63.016px;
    font-size: 0px;
    z-index: 206;
  }
  .menu {
    display: block;
    position: relative;
    height: 65.641px;
    margin: 0 0 0 0.62px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 65.641px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 2.36px;
    z-index: 211;
  }
  .div-59 {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 897.969px;
    height: 2.626px;
    margin: 54.836px 0 0 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: -Infinity;
  }
  .flex-row-f {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 559.734px;
    height: 43px;
    margin: 84.304px 0 0 0;
    z-index: 215;
  }
  .home {
    flex-shrink: 0;
    position: relative;
    height: 43px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29px;
    font-weight: 500;
    line-height: 43px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.43px;
    z-index: 212;
  }
  .mission {
    flex-shrink: 0;
    position: relative;
    height: 43px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29px;
    font-weight: 500;
    line-height: 43px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.43px;
    z-index: 215;
  }
  .flex-row-e {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 595.734px;
    height: 43px;
    margin: 41.02px 0 0 0;
    z-index: 216;
  }
  .about-us {
    flex-shrink: 0;
    position: relative;
    height: 43px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29px;
    font-weight: 500;
    line-height: 43px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.43px;
    z-index: 213;
  }
  .products-5a {
    flex-shrink: 0;
    position: relative;
    height: 43px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29px;
    font-weight: 500;
    line-height: 43px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.43px;
    z-index: 216;
  }
  .flex-row-ad {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 622.734px;
    height: 43px;
    margin: 41.02px 0 0 0;
    z-index: 217;
  }
  .our-pillars {
    flex-shrink: 0;
    position: relative;
    height: 43px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29px;
    font-weight: 500;
    line-height: 43px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.43px;
    z-index: 214;
  }
  .contact-us {
    flex-shrink: 0;
    position: relative;
    height: 43px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29px;
    font-weight: 500;
    line-height: 43px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.43px;
    z-index: 217;
  }
  .div-5b {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 897.969px;
    height: 2.626px;
    margin: 83.031px 0 0 0;
    background: rgba(255, 255, 255, 0.3);
    z-index: -Infinity;
  }
  .flex-row-a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 874.968px;
    height: 47.262px;
    margin: 78.769px 0 0 23px;
    z-index: 220;
  }
  .all-rights-reserved {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    width: 233px;
    height: 29px;
    color: #ffffff;
    font-family: DM Sans, var(--default-font-family);
    font-size: 29px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    white-space: nowrap;
    z-index: 220;
  }
  .socials {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 31.508px;
    position: relative;
    width: 204.8px;
    z-index: 207;
  }
  .insta {
    flex-shrink: 0;
    position: relative;
    width: 47.262px;
    height: 47.262px;
    background: url(./assets/images/565de371-e0d9-4ad7-a55c-16b2e92d8fc4.png)
      no-repeat center;
    background-size: cover;
    z-index: 208;
    overflow: hidden;
    border-radius: 1312.821px;
  }
  .facebook {
    flex-shrink: 0;
    position: relative;
    width: 47.262px;
    height: 47.262px;
    background: url(./assets/images/5f0a3610-9d23-48b3-afd2-f93fe782b95d.png)
      no-repeat center;
    background-size: cover;
    z-index: 209;
    overflow: hidden;
    border-radius: 1312.821px;
  }
  .linked-in {
    flex-shrink: 0;
    position: relative;
    width: 47.262px;
    height: 47.262px;
    background: url(./assets/images/5a07f597-b38b-4ce4-81a2-87ffd0c322b3.png)
      no-repeat center;
    background-size: cover;
    z-index: 210;
    overflow: hidden;
    border-radius: 1312.821px;
  }
  .logo-crypto {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    width: 296.172px;
    height: 66.679px;
    top: 76.602px;
    right: 100.039px;
    z-index: 221;
  }
  .header-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8.402px;
    position: relative;
    width: 323.479px;
    z-index: 222;
    overflow: hidden;
  }
  .logo {
    flex-shrink: 0;
    position: relative;
    width: 295.752px;
    height: 66.679px;
    z-index: 223;
    overflow: hidden;
  }
  .group-5c {
    position: relative;
    width: 351.031px;
    height: 76.573px;
    margin: 0px 0 0 0;
    background: url(./assets/images/e6056aaf-7e84-4167-b116-41b0e56191b3.png)
      no-repeat center;
    background-size: cover;
    z-index: 224;
  }
  .pic-18 {
    position: absolute;
    width: 1024px;
    height: 28513px;
    top: 0;
    left: 0;
    background: url(./assets/images/f3d7e1dffac34ccf6e560dba84256bd091796204.png)
      no-repeat center;
    background-size: cover;
  }
  .logo-crypto-5d {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    width: 357.087px;
    height: 126.031px;
    top: 63.016px;
    left: 63.008px;
    z-index: 89;
  }
  .logo-crypto-5e {
    flex-shrink: 0;
    position: relative;
    width: 357.087px;
    height: 126.031px;
    z-index: 90;
  }
  .logo-crypto-5f {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 310.981px;
    margin: 25.047px 0 0 17.379px;
    z-index: 91;
  }
  .header-logo-60 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8.822px;
    position: relative;
    width: 339.653px;
    z-index: 92;
    overflow: hidden;
  }
  .logo-61 {
    flex-shrink: 0;
    position: relative;
    width: 310.54px;
    height: 70.013px;
    z-index: 93;
    overflow: hidden;
  }
  .group-62 {
    position: relative;
    width: 368.582px;
    height: 80.402px;
    margin: 0px 0 0 0;
    background: url(./assets/images/66b65e59-396e-4dde-8450-328825f87fdd.png)
      no-repeat center;
    background-size: cover;
    z-index: 94;
  }
  .menu-63 {
    position: absolute;
    width: 82.708px;
    height: 82.708px;
    top: 80.738px;
    right: 80.745px;
    background: #4ba629;
    z-index: 95;
    border-radius: 1837.949px;
  }
  .div-64 {
    position: relative;
    width: 45.949px;
    height: 36.759px;
    margin: 23.893px 0 0 18.379px;
    background: url(./assets/images/5fa8eb49-342b-49fc-9d84-0b70a0e0998f.png)
      no-repeat center;
    background-size: cover;
    z-index: 96;
  }
}
